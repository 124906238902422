import { useIntersectionObserver } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../../styles/animations/jeu-aide-machine/bingo.module.css';

const propTypes = {
    className: PropTypes.string,
};

function Bingo({ className }) {
    const {
        ref: containerRef,
        entry: { isIntersecting = false },
    } = useIntersectionObserver({ rootMargin: '-20%' });

    return (
        <div
            className={classNames([
                styles.container,
                className,
                { [styles.visible]: isIntersecting },
            ])}
            ref={containerRef}
        >
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 319 383.1">
                <g id="BINGO">
                    <path
                        d="M319,13.2c0-.9,0-1.8,0-2.7-.3-5.8-5.2-10.5-11.1-10.5-8.8,0-17.6,0-26.4,0C191.6,0,101.6.1,11.6,0,6.1,0,0,4.3,0,11.6c0,14.6,0,29.1,0,43.7v233.7C0,316.3,0,343.6,0,370.9c0,.9,0,1.9.2,2.8,1.4,5.8,5.7,9.3,11.8,9.3,14.9,0,29.8,0,44.6,0,82.3,0,164.6,0,246.9,0,1.3,0,2.6,0,3.9,0,6.7-.1,11.5-5.1,11.5-11.8,0-8,0-16.1,0-24.1v-155.5c0-59.5,0-118.9,0-178.4ZM78.9,198.7c0-1.2.3-1.5,1.5-1.5,16.4,0,32.8,0,49.2,0,1.2,0,1.5.2,1.5,1.5,0,8.2,0,16.5,0,24.7s0,16.5,0,24.8c0,1.2-.3,1.5-1.5,1.5-16.4,0-32.8,0-49.2,0-1.2,0-1.5-.3-1.5-1.5,0-16.5,0-33,0-49.5ZM131,114.4c0,8.2,0,16.5,0,24.8,0,1.1-.3,1.4-1.4,1.4-16.5,0-32.9,0-49.4,0-1.1,0-1.4-.3-1.4-1.4,0-16.5,0-33,0-49.5,0-1.2.3-1.5,1.5-1.5,16.4,0,32.8,0,49.2,0,1.2,0,1.5.3,1.5,1.5,0,8.2,0,16.5,0,24.8ZM281.2,339c-.6,1.3-1.4,2.2-2.9,2.2-1.5,0-2.5-.8-3.2-2.1-.4-.9-.7-1.8-.6-2.7,0-2.5.9-3.9,2.7-4.5,1.5-.5,2.9,0,3.6,1.3,1,1.9,1.3,3.8.4,5.8ZM294.7,302.8c0,1.2-.3,1.5-1.5,1.5-16.5,0-32.9,0-49.4,0-1.3,0-1.5-.4-1.5-1.6,0-8.2,0-16.4,0-24.6s0-16.4,0-24.6c0-1.3.4-1.6,1.7-1.6,16.4,0,32.8,0,49.2,0,1.2,0,1.5.2,1.5,1.4,0,16.5,0,33,0,49.5ZM294.7,114.3c0,8.2,0,16.5,0,24.8,0,1.2-.3,1.5-1.5,1.5-16.5,0-32.9,0-49.4,0-1.3,0-1.5-.4-1.5-1.6,0-16.3,0-32.7,0-49,0-1.4.4-1.8,1.8-1.8,16.3,0,32.7,0,49,0,1.3,0,1.5.4,1.5,1.6,0,8.2,0,16.4,0,24.6Z"
                        fill="#fdfdfd"
                    />
                    <path
                        d="M319,13.2c0-.9,0-1.8,0-2.7-.3-5.8-5.2-10.5-11.1-10.5-8.8,0-17.6,0-26.4,0C191.6,0,101.6.1,11.6,0,6.1,0,0,4.3,0,11.6c0,14.6,0,29.1,0,43.7v233.7C0,316.3,0,343.6,0,370.9c0,.9,0,1.9.2,2.8,1.4,5.8,5.7,9.3,11.8,9.3,14.9,0,29.8,0,44.6,0,82.3,0,164.6,0,246.9,0,1.3,0,2.6,0,3.9,0,6.7-.1,11.5-5.1,11.5-11.8,0-8,0-16.1,0-24.1v-155.5c0-59.5,0-118.9,0-178.4ZM78.9,198.7c0-1.2.3-1.5,1.5-1.5,16.4,0,32.8,0,49.2,0,1.2,0,1.5.2,1.5,1.5,0,8.2,0,16.5,0,24.7s0,16.5,0,24.8c0,1.2-.3,1.5-1.5,1.5-16.4,0-32.8,0-49.2,0-1.2,0-1.5-.3-1.5-1.5,0-16.5,0-33,0-49.5ZM131,114.4c0,8.2,0,16.5,0,24.8,0,1.1-.3,1.4-1.4,1.4-16.5,0-32.9,0-49.4,0-1.1,0-1.4-.3-1.4-1.4,0-16.5,0-33,0-49.5,0-1.2.3-1.5,1.5-1.5,16.4,0,32.8,0,49.2,0,1.2,0,1.5.3,1.5,1.5,0,8.2,0,16.5,0,24.8ZM281.2,339c-.6,1.3-1.4,2.2-2.9,2.2-1.5,0-2.5-.8-3.2-2.1-.4-.9-.7-1.8-.6-2.7,0-2.5.9-3.9,2.7-4.5,1.5-.5,2.9,0,3.6,1.3,1,1.9,1.3,3.8.4,5.8ZM294.7,302.8c0,1.2-.3,1.5-1.5,1.5-16.5,0-32.9,0-49.4,0-1.3,0-1.5-.4-1.5-1.6,0-8.2,0-16.4,0-24.6s0-16.4,0-24.6c0-1.3.4-1.6,1.7-1.6,16.4,0,32.8,0,49.2,0,1.2,0,1.5.2,1.5,1.4,0,16.5,0,33,0,49.5ZM294.7,114.3c0,8.2,0,16.5,0,24.8,0,1.2-.3,1.5-1.5,1.5-16.5,0-32.9,0-49.4,0-1.3,0-1.5-.4-1.5-1.6,0-16.3,0-32.7,0-49,0-1.4.4-1.8,1.8-1.8,16.3,0,32.7,0,49,0,1.3,0,1.5.4,1.5,1.6,0,8.2,0,16.4,0,24.6Z"
                        fill="#e8452d"
                    />
                    <path
                        d="M240.2,253.2c0-1.2-.4-1.4-1.5-1.4-16.4,0-32.8,0-49.2,0-1.3,0-1.7.3-1.7,1.6,0,8.2,0,16.4,0,24.6s0,16.4,0,24.6c0,1.2.2,1.6,1.5,1.6,16.5,0,32.9,0,49.3,0,1.2,0,1.4-.4,1.4-1.5,0-16.5,0-33,0-49.5Z"
                        fill="#fff"
                    />
                    <g className={styles.bingo}>
                        <path
                            d="M23.1,20.9c11.9,0,23.8,0,35.6,0,4.7,0,9.2.8,13.1,3.7,3.9,2.9,5.2,6.9,4.8,11.5-.5,4.7-3.3,7.7-7.7,9.3-.2,0-.4.1-.7.3,1.3.5,2.5.9,3.6,1.5,4.9,2.7,7,8.3,5.4,14.1-1.3,4.7-4.7,7.3-9.1,8.8-2.7.9-5.5,1.3-8.4,1.3-12.2,0-24.4,0-36.6,0-.3-.4-.2-.8-.2-1.2,0-16,0-32,0-48,0-.4-.1-.8.2-1.2Z"
                            fill="#7f1810"
                        />
                        <path
                            d="M81.8,20.9c5.1,0,10.2,0,15.3,0,1.1,0,1.4.3,1.4,1.4,0,15.9,0,31.8,0,47.7,0,1.1-.4,1.4-1.4,1.4-5.1,0-10.2,0-15.3,0-.3-.4-.2-.8-.2-1.2,0-16,0-32,0-48,0-.4-.1-.8.2-1.2Z"
                            fill="#7f1810"
                        />
                        <path
                            d="M162.1,71.4c-6,0-12.1,0-18.1,0-1.1,0-1.8-.4-2.4-1.3-6.8-10.1-13.7-20.1-20.6-30.1-.2-.3-.4-.5-.6-.7-.8.3-.4,1-.4,1.4,0,9.7,0,19.3,0,29,0,.6.2,1.2-.2,1.7-4.8,0-9.6,0-14.4,0-1.1,0-1.4-.3-1.4-1.4,0-15.9,0-31.7,0-47.6,0-1.2.3-1.5,1.5-1.5,5.5,0,11,0,16.6,0,1.3,0,2,.4,2.7,1.4,4.9,7.1,9.7,14.2,14.6,21.3,2.2,3.2,4.4,6.4,6.7,9.7.4-.6.2-1.2.2-1.7,0-9.7,0-19.3,0-29,0-1.4.3-1.8,1.7-1.7,4.7,0,9.4,0,14,0,.3.4.2.8.2,1.2,0,16,0,32,0,48,0,.4.1.8-.2,1.2Z"
                            fill="#7f1810"
                        />
                        <path
                            d="M217,62.8c-6.7,8.4-15.5,10.4-25.3,9.6-4.7-.4-9.1-1.6-13.1-4.1-6.2-3.8-10.1-9.3-11.3-16.5-1.3-7.6-.1-14.8,4.6-21.1,4.2-5.6,9.9-8.8,16.6-10.3,9.2-2.1,18.1-1.6,26.6,3,4.7,2.5,8,6.2,9.8,11.3.4,1,.3,1.5-1,1.7-4.3.7-8.6,1.5-12.8,2.3-.6.1-1.2.5-1.6-.4-1.5-3.4-4.3-5-7.8-5.8-4.3-1-8.5-.4-12.2,2-7.8,5-8.6,19.5,1.9,24.2,5.9,2.7,11.8,2.7,17.6,0,2.5-1.2,4.4-3,5.3-5.8.4-1.2,0-1.4-1.1-1.4-6.5,0-13,0-19.6,0-1.4,0-2-.3-1.9-1.8.1-2,0-4.1,0-6.1,0-.8.2-1.2,1.1-1.2,12.2,0,24.4,0,36.6,0,.9,0,1.2.3,1.2,1.2,0,8.9,0,17.8,0,26.6,0,1-.4,1.2-1.3,1.2-3.4,0-6.7,0-10.1,0-.9,0-1.3-.3-1.3-1.2-.2-2.4-.6-4.8-.9-7.4Z"
                            fill="#7f1810"
                        />
                        <path
                            d="M266.3,73.3c-7-.2-14.1-1.4-20.4-5.4-7.4-4.7-11.3-11.5-11.7-20.2-.7-14.3,8.5-23.7,20.6-26.8,10.7-2.7,21.2-2.3,31.1,2.9,10.5,5.6,14.4,15.6,13.1,25.9-1.7,12.6-9.7,19.2-21.4,22.3-3.6.9-7.2,1.3-11.3,1.3Z"
                            fill="#7f1810"
                        />
                    </g>
                    <path
                        d="M75.1,88.1c-16.4,0-32.8,0-49.2,0-1.4,0-1.6.4-1.6,1.7,0,8.2,0,16.4,0,24.6s0,16.4,0,24.6c0,1.1.2,1.5,1.4,1.5,16.5,0,32.9,0,49.4,0,1.2,0,1.4-.4,1.4-1.5,0-16.4,0-32.9,0-49.3,0-1.1-.2-1.5-1.4-1.5Z"
                        fill="#fff"
                    />
                    <path
                        d="M76.5,169c0-8.2,0-16.5,0-24.8,0-1.3-.4-1.5-1.6-1.5-16.4,0-32.8,0-49.2,0-1.2,0-1.5.3-1.5,1.5,0,16.5,0,33,0,49.5,0,1.2.4,1.4,1.5,1.4,16.4,0,32.8,0,49.2,0,1.2,0,1.6-.3,1.6-1.5,0-8.2,0-16.4,0-24.6Z"
                        fill="#fff"
                    />
                    <path
                        d="M131,278c0-8.2,0-16.5,0-24.8,0-1.2-.3-1.5-1.5-1.5-16.4,0-32.8,0-49.2,0-1.2,0-1.5.3-1.5,1.5,0,16.5,0,33,0,49.5,0,1.2.3,1.5,1.5,1.5,16.4,0,32.8,0,49.2,0,1.2,0,1.5-.3,1.5-1.5,0-8.2,0-16.5,0-24.7Z"
                        fill="#fff"
                    />
                    <path
                        d="M240.1,114.5c0-8.2,0-16.5,0-24.8,0-1.2-.2-1.6-1.5-1.6-16.4,0-32.8,0-49.2,0-1.2,0-1.6.3-1.6,1.6,0,16.4,0,32.9,0,49.3,0,1.2.4,1.5,1.5,1.5,16.5,0,32.9,0,49.3,0,1.2,0,1.5-.2,1.5-1.4,0-8.2,0-16.4,0-24.6Z"
                        fill="#fff"
                    />
                    <path
                        d="M294.7,169c0-8.2,0-16.4,0-24.6,0-1.3-.2-1.8-1.7-1.8-16.3,0-32.6,0-48.9,0-1.4,0-1.8.3-1.8,1.7,0,16.4,0,32.8,0,49.2,0,1.2.3,1.5,1.5,1.5,16.5,0,32.9,0,49.4,0,1.2,0,1.5-.2,1.5-1.5,0-8.2,0-16.4,0-24.6Z"
                        fill="#fff"
                    />
                    <path
                        d="M131,144.2c0-1.3-.4-1.5-1.6-1.5-16.4,0-32.7,0-49.1,0-1.3,0-1.5.4-1.5,1.6,0,8.2,0,16.4,0,24.6s0,16.5,0,24.8c0,1.2.3,1.5,1.5,1.5,16.4,0,32.8,0,49.2,0,1.2,0,1.4-.4,1.4-1.5,0-16.5,0-32.9,0-49.4Z"
                        fill="#fff"
                    />
                    <path
                        d="M131,307.9c0-1.1-.2-1.5-1.4-1.5-16.4,0-32.8,0-49.2,0-1.2,0-1.5.2-1.5,1.5,0,8.2,0,16.4,0,24.6s0,16.5,0,24.8c0,1.2.2,1.6,1.5,1.6,16.4,0,32.7,0,49.1,0,1.2,0,1.6-.3,1.6-1.5,0-16.5,0-32.9,0-49.4Z"
                        fill="#fff"
                    />
                    <path
                        d="M240.1,223.5c0-8.2,0-16.4,0-24.6,0-1.3-.3-1.6-1.6-1.6-16.3,0-32.6,0-48.9,0-1.4,0-1.9.3-1.9,1.8,0,16.3,0,32.7,0,49,0,1.2.2,1.6,1.5,1.6,16.4,0,32.9,0,49.3,0,1.2,0,1.5-.3,1.5-1.5,0-8.2,0-16.5,0-24.7Z"
                        fill="#fff"
                    />
                    <path
                        d="M240.1,144.1c0-1.1-.2-1.4-1.4-1.4-16.5,0-32.9,0-49.4,0-1.2,0-1.6.3-1.6,1.6,0,8.2,0,16.5,0,24.8,0,8.2,0,16.4,0,24.6,0,1,.2,1.5,1.3,1.5,16.6,0,33.1,0,49.7,0,1,0,1.3-.3,1.3-1.3,0-16.6,0-33.1,0-49.7Z"
                        fill="#fff"
                    />

                    <path
                        d="M240.1,332.6c0,8.3,0,16.5,0,24.8,0,1.1-.2,1.4-1.4,1.4-16.5,0-33,0-49.5,0-1.1,0-1.4-.3-1.4-1.4,0-16.6,0-33.1,0-49.7,0-1.1.3-1.3,1.3-1.3,16.6,0,33.1,0,49.7,0,1,0,1.3.3,1.3,1.3,0,8.3,0,16.6,0,24.9Z"
                        fill="#fff"
                    />

                    <path
                        d="M76.5,198.7c0-1.2-.3-1.5-1.5-1.5-16.5,0-32.9,0-49.4,0-1.1,0-1.4.3-1.4,1.4,0,16.5,0,33,0,49.5,0,1.1.2,1.5,1.4,1.5,8.2,0,16.4,0,24.6,0s16.5,0,24.8,0c1.1,0,1.4-.3,1.4-1.4,0-16.5,0-33,0-49.5Z"
                        fill="#fff"
                    />
                    <path
                        d="M76.5,307.8c0-1.1-.4-1.4-1.4-1.4-16.5,0-32.9,0-49.4,0-1.2,0-1.5.3-1.4,1.5,0,8.2,0,16.5,0,24.8s0,16.5,0,24.8c0,1.1.2,1.4,1.4,1.4,16.5,0,32.9,0,49.4,0,1.2,0,1.5-.4,1.5-1.5,0-16.5,0-33,0-49.5Z"
                        fill="#fff"
                    />
                    <path
                        d="M185.5,278c0-8.2,0-16.5,0-24.8,0-1.2-.3-1.4-1.5-1.4-16.4,0-32.8,0-49.2,0-1.2,0-1.5.2-1.5,1.5,0,16.5,0,33,0,49.5,0,1.2.4,1.5,1.5,1.4,16.4,0,32.8,0,49.2,0,1.2,0,1.5-.3,1.5-1.5,0-8.2,0-16.5,0-24.8Z"
                        fill="#fff"
                    />
                    <path
                        d="M185.5,114.4c0-8.2,0-16.4,0-24.6,0-1.3-.3-1.7-1.6-1.6-16.3,0-32.6,0-48.9,0-1.4,0-1.7.4-1.7,1.8,0,16.4,0,32.8,0,49.2,0,1.2.3,1.5,1.5,1.4,16.4,0,32.9,0,49.3,0,1.1,0,1.4-.3,1.4-1.4,0-8.2,0-16.5,0-24.8Z"
                        fill="#fff"
                    />
                    <path
                        d="M185.5,223.6c0,8.2,0,16.4,0,24.6,0,1.2-.3,1.5-1.5,1.4-16.4,0-32.8,0-49.2,0-1.1,0-1.5-.2-1.5-1.5,0-16.4,0-32.9,0-49.3,0-1.3.4-1.6,1.7-1.6,16.3,0,32.6,0,48.9,0,1.3,0,1.6.3,1.6,1.6,0,8.2,0,16.5,0,24.8Z"
                        fill="#fff"
                    />
                    <path
                        d="M294.7,332.6c0-8.2,0-16.5,0-24.8,0-1-.1-1.5-1.3-1.5-16.6,0-33.1,0-49.7,0-1,0-1.3.2-1.3,1.3,0,16.5,0,33,0,49.5,0,1.3.4,1.6,1.6,1.6,16.4,0,32.8,0,49.2,0,1.3,0,1.5-.4,1.5-1.6,0-8.2,0-16.4,0-24.6Z"
                        fill="#fff"
                    />

                    <path
                        d="M76.5,277.9c0-8.2,0-16.3,0-24.5,0-1.2-.2-1.6-1.5-1.6-16.4,0-32.8,0-49.2,0-1.1,0-1.5.2-1.5,1.4,0,16.5,0,33,0,49.5,0,1.2.3,1.5,1.5,1.5,16.4,0,32.8,0,49.2,0,1.3,0,1.6-.4,1.5-1.6,0-8.2,0-16.5,0-24.8Z"
                        fill="#fff"
                    />
                    <path
                        d="M185.5,168.9c0-8.3,0-16.5,0-24.8,0-1.2-.2-1.5-1.5-1.5-16.4,0-32.8,0-49.2,0-1.3,0-1.5.4-1.5,1.6,0,16.5,0,32.9,0,49.4,0,1.2.3,1.5,1.5,1.5,16.4,0,32.9,0,49.3,0,1.1,0,1.4-.3,1.4-1.4,0-8.2,0-16.5,0-24.7Z"
                        fill="#fff"
                    />
                    <path
                        d="M185.5,332.6c0-8.3,0-16.6,0-24.9,0-1-.3-1.3-1.3-1.3-16.5,0-33,0-49.5,0-1.1,0-1.4.4-1.4,1.4,0,16.5,0,33,0,49.5,0,1.2.3,1.5,1.5,1.5,16.4,0,32.8,0,49.2,0,1.3,0,1.5-.5,1.5-1.6,0-8.2,0-16.4,0-24.6Z"
                        fill="#fff"
                    />
                    <path
                        d="M294.7,198.7c0-1.2-.4-1.4-1.5-1.4-16.5,0-32.9,0-49.3,0-1.2,0-1.5.3-1.5,1.5,0,8.2,0,16.5,0,24.8h0c0,8.2,0,16.4,0,24.6,0,1.2.2,1.6,1.5,1.6,16.5,0,32.9,0,49.4,0,1.2,0,1.5-.3,1.5-1.5,0-16.5,0-33,0-49.5Z"
                        fill="#fff"
                    />

                    <path
                        d="M162.1,71.4c0-16.8,0-33.6,0-50.4.6.4.3,1.1.3,1.6,0,15.7,0,31.5,0,47.2,0,.5.2,1.2-.3,1.6Z"
                        fill="#e52e13"
                    />
                    <path
                        d="M81.8,20.9c0,16.8,0,33.6,0,50.4-.6-.4-.3-1.1-.3-1.6,0-15.7,0-31.5,0-47.2,0-.5-.2-1.2.3-1.6Z"
                        fill="#e52e13"
                    />
                    <path
                        d="M23.1,20.9c0,16.8,0,33.6,0,50.4-.6-.4-.3-1.1-.3-1.6,0-15.7,0-31.5,0-47.2,0-.5-.2-1.2.3-1.6Z"
                        fill="#e52e13"
                    />
                    <path
                        d="M119.9,71.4c0-10.4,0-20.7,0-31.1,0-.4-.1-.9,0-1.2.3-.6.4,0,.5.2-.5.5-.3,1.2-.3,1.8,0,9.5,0,19.1,0,28.6,0,.6.3,1.3-.3,1.8Z"
                        fill="#e52e13"
                    />
                    <path
                        d="M48.3,50.6c2.5,0,4.9,0,7.4,0,3.2.1,5.2,2.3,5.2,5.4,0,2.4-1.6,4.1-4.1,4.7-1.6.4-3.2.3-4.9.3-3.6,0-7.2,0-10.9,0-1,0-1.4-.2-1.4-1.3,0-2.6,0-5.2,0-7.9,0-1.1.3-1.3,1.4-1.3,2.4,0,4.8,0,7.2,0,0,0,0,0,0,0Z"
                        fill="#e8452d"
                    />
                    <path
                        d="M47.6,41.4c-2.2,0-4.4,0-6.6,0-.9,0-1.3-.2-1.3-1.2,0-2.6,0-5.1,0-7.7,0-1,.4-1.2,1.3-1.2,4.3,0,8.6,0,12.9,0,2.3,0,4.6.6,5.6,3,1.5,3.5-1,6.9-5.1,7-2.3,0-4.5,0-6.8,0h0Z"
                        fill="#e8452d"
                    />
                    <path
                        d="M147,218.4c1.8-.5,3.6-.6,5.4-.9.9-.1,1.4-.5,1.7-1.3,1.5-3.1,3-6.1,4.5-9.2.2-.4.2-.9.7-.9.6,0,.7.6.9,1,1.5,3.1,3.1,6.1,4.5,9.2.4.8.9,1.1,1.8,1.3,3.8.5,7.7,1.1,11.5,1.7.2.6-.3.8-.6,1-2.5,2.5-5,4.9-7.6,7.4-.6.5-1,1.1-.8,1.9-.3.9,0,1.7.4,2.4.6,2.2.9,4.5,1.2,6.7-.2.3-.1.6-.1.9,0,0,0,.2,0,.3,0-.2,0-.3.1-.3.3.6.3,1.2.3,2-3.1-1.6-6.1-3-8.9-4.7-1.9-1.1-3.3-1.3-5.3,0-2.5,1.6-5.2,2.8-7.7,4.2-.7.4-1.4.6-1.2-.6.5-2.8.8-5.6,1.5-8.3.2.1.3.3,0,.4.1,0,.2-.2.2-.3,0-.3,0-.7-.1-1,.8-2.1-.3-3.3-1.7-4.7-2.4-2.3-4.5-4.8-6.8-7.2,1.4-.4,3.2-.6,5-.7.5.2,1,0,1.5,0,.2-.1,0-.2,0,0,0,0,0,0,0,0-.2,0-.4,0-.5-.2Z"
                        fill="#f39989"
                    />
                    <path
                        d="M169.5,231.9c0,.1-.1.2-.2.4,0-.9-.9-1.8-.2-2.8.1.8.2,1.6.4,2.4Z"
                        fill="#f28e7a"
                    />
                    <path
                        d="M147,218.4c.2,0,.5,0,1.1.2-.9.2-1.5.3-2-.1.3,0,.6,0,.9,0Z"
                        fill="#f0816c"
                    />
                    <path
                        d="M149.6,231c.4.3.3.7.3,1.1,0,0-.1,0-.2.1,0-.1-.1-.3-.2-.4,0-.3,0-.6,0-.9Z"
                        fill="#ef7f6a"
                    />
                    <path
                        d="M170.8,239.5c0,.1,0,.3-.2.4-.2,0-.2-.2-.2-.3,0-.3,0-.7.3-.9,0,.3,0,.6.1.8Z"
                        fill="#ef7a64"
                    />
                    <path
                        d="M266.6,60.4c-5.4,0-10.3-1.5-13.5-6.3-4.6-6.7-2.9-19.2,9.5-21.5,5-.9,9.9-.5,14.2,2.5,4.7,3.2,6.2,8,5.4,13.4-.8,5.9-4.5,9.5-10.2,11.2-1.8.5-3.6.8-5.5.7Z"
                        fill="#e8452d"
                    />
                    <path
                        d="M131,114.4c0-8.2,0-16.4,0-24.6,0-1.3-.3-1.7-1.6-1.6-16.3,0-32.6,0-48.9,0-1.4,0-1.7.4-1.7,1.8,0,16.4,0,32.8,0,49.2,0,1.2.3,1.5,1.5,1.4,16.4,0,32.9,0,49.3,0,1.1,0,1.4-.3,1.4-1.4,0-8.2,0-16.5,0-24.8Z"
                        fill="#fff"
                    />
                    <path
                        d="M131,198.8c0-1.3-.4-1.5-1.6-1.5-16.4,0-32.7,0-49.1,0-1.3,0-1.5.4-1.5,1.6,0,8.2,0,16.4,0,24.6s0,16.5,0,24.8c0,1.2.3,1.5,1.5,1.5,16.4,0,32.8,0,49.2,0,1.2,0,1.4-.4,1.4-1.5,0-16.5,0-32.9,0-49.4Z"
                        fill="#fff"
                    />
                    <path
                        d="M294.6,114.3c0-8.2,0-16.5,0-24.8,0-1.2-.3-1.4-1.5-1.4-16.4,0-32.8,0-49.2,0-1.2,0-1.5.2-1.5,1.5,0,16.5,0,33,0,49.5,0,1.2.4,1.5,1.5,1.4,16.4,0,32.8,0,49.2,0,1.2,0,1.5-.3,1.5-1.5,0-8.2,0-16.5,0-24.8Z"
                        fill="#fff"
                    />
                    <path
                        d="M294.6,278c0-8.2,0-16.5,0-24.8,0-1.2-.3-1.4-1.5-1.4-16.4,0-32.8,0-49.2,0-1.2,0-1.5.2-1.5,1.5,0,16.5,0,33,0,49.5,0,1.2.4,1.5,1.5,1.4,16.4,0,32.8,0,49.2,0,1.2,0,1.5-.3,1.5-1.5,0-8.2,0-16.5,0-24.8Z"
                        fill="#fff"
                    />
                </g>
                <g id="PASTILLE">
                    <circle
                        cx="50.3"
                        cy="114.3"
                        r="19.8"
                        fill="#e8452d"
                        className={styles.circle}
                    />
                    <circle
                        cx="104.9"
                        cy="168.9"
                        r="19.8"
                        fill="#e8452d"
                        className={styles.circle}
                    />
                    <circle cx="214" cy="114.3" r="19.8" fill="#e8452d" className={styles.circle} />
                    <circle
                        cx="266.6"
                        cy="168.9"
                        r="19.8"
                        fill="#e8452d"
                        className={styles.circle}
                    />
                    <circle cx="214" cy="278" r="19.8" fill="#e8452d" className={styles.circle} />
                    <circle
                        cx="50.4"
                        cy="332.6"
                        r="19.8"
                        fill="#e8452d"
                        className={styles.circle}
                    />
                    <circle
                        cx="268.6"
                        cy="332.6"
                        r="19.8"
                        fill="#e8452d"
                        className={styles.circle}
                    />
                </g>
            </svg>
        </div>
    );
}

// eslint-disable-next-line @eslint-react/no-prop-types
Bingo.propTypes = propTypes;

export default Bingo;
