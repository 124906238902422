import classNames from 'classnames';
import PropTypes from 'prop-types';

import PlayingCard from './PlayingCard';

import styles from '../../styles/animations/jeu-aide-machine/playing-cards.module.css';

const propTypes = {
    className: PropTypes.string,
};

function PlayingCards({ className }) {
    return (
        <div className={classNames([styles.container, className])}>
            <PlayingCard
                className={styles.card}
                number="1"
                suit="diamonds"
                text="Le joueur mise de l’argent (ou un objet de valeur) lorsqu’il joue"
            />
            <PlayingCard
                className={styles.card}
                number="2"
                suit="spades"
                text="La mise est irréversible"
            />
            <PlayingCard
                className={styles.card}
                number="3"
                suit="clubs"
                text="Le joueur gagne OU perd"
            />
            <PlayingCard
                className={styles.card}
                number="4"
                suit="hearts"
                text="L’issue du jeu repose totalement ou en partie sur le hasard"
            />
        </div>
    );
}

// eslint-disable-next-line @eslint-react/no-prop-types
PlayingCards.propTypes = propTypes;

export default PlayingCards;
