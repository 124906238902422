import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../../styles/animations/jeu-aide-machine/crying.module.css';

const propTypes = {
    className: PropTypes.string,
};

function Crying({ className }) {
    return (
        <div className={classNames([styles.container, className])}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 279.4 386.8">
                <g id="SAD">
                    <path
                        d="M268.9,317.5c-1.5,14.1-6.4,26.5-18.6,34.8-6.5,4.4-13.8,6.1-21.4,6.5-3.5.2-7,0-10.5-.4-6.4,0-11.6-2.8-16.2-6.9-6-5.3-9.4-12.2-12.3-19.5-4.2-10.8-6.7-22-9.4-33.2-1.6-6.6-3.1-13.2-4.8-20.1-1.9,6.3-3.5,12.5-5.2,18.6-3.9,13.6-7.6,27.2-11.5,40.7-.4,1.3-.4,2.6-1.1,3.8-1.6,2.2-1.7,5-2.4,7.5-.6,2.2-1.6,3.9-3.9,4.4-2.1,1.3-4.4.6-6.5.1-6.3-1.3-11.8-4.3-17.2-7.7,0,0,0,0-.1,0,0,0,0,0,0,0-2.8-2-5.4-4-7.6-6.6-1.8-2.2-2.4-4.6-2-7.4,1.3-9.5,2-19.1,3-28.6,0-.7,0-1.3,0-2v-.4c0-.1,0-.3,0-.4.7-7.7,1.5-15.4,2.2-23.1.8-8.7,1.6-17.4,2.5-26.1.9-9.2,1.9-18.3,3-27.4.6-5.1,1.4-10.2,2.2-15.3.1-.9.2-1.7.8-2.5,0,0,.1,0,.2-.1,0,0,0,0,0,0,.6-.5,1.2-.3,1.8-.1,6.8,2.4,14,2.7,21.1,3.5,5.8.7,11.3,2.7,16.9,4.2,15.6,4.3,30.9,9.7,46.6,13.8.8.2,1.6.4,2.2.9,0,0,.1.2.2.2,0,0,.1.1.2.2,8.3,10.3,16.4,20.8,24.8,31.1,3.7,4.6,7.5,9.3,11.5,13.7.8.9,1.3,2,2,2.9.5,1.4,1.7,2.3,2.5,3.5,3.8,6.1,5.7,12.8,6.7,19.9.9,5.8,1,11.7.4,17.6Z"
                        fill="#42171a"
                    />
                    <path
                        d="M279.4,159.2c0,7.1-1.5,14-2.5,21-1.2,8.3-2.3,16.6-4.4,24.8-2.6,9.9-7.1,18.9-13.5,27-1,1.3-2.4,1.8-3.9,2.1-.7.1-1.4.2-2.1.4-.2.2-.5.4-.9.4-3.6,0-7-.8-9.9-3,0,0,0-.1,0-.2h0c0-.4.2-.6.4-.8.3-.2.6-.3,1-.4.3,0,.7-.2.9-.4,2.5-.8,5.1-1.2,7.5-2.4,7.1-3.5,9.7-10.9,6.1-17.9-1.6-3.2-3.9-5.8-7-7.7-.7-.4-1.4-.8-1.8-1.5,0,0,0,0,0,0-.3-.5-.7-.9-1.1-1.1-.6-.3-1.2-.5-1.9-.5,0,0-.2,0-.3,0-1.4-.1-2.8-.4-4.1-.5-.6,0-1.1-.2-1.5-.3-1.9-.6-3-1.9-3.5-4.2-1.5-7.3-2-14.7-2.8-22.1-.3-3-.4-6-.4-9.3,0,2.8,0,5.3,0,7.8,0,8.8-.8,17.5-.9,26.2,0,.6,0,1.2-.3,1.7,0,0,0,0,0,.1,0,0-.1.2-.2.2-.9.5-1.9.4-2.9.4-3.1,0-6.2,0-9.3.2-7.5.4-15,.6-22.5.8-.3-.1-.6-.3-.9-.5-.1,0-.2-.2-.3-.3-.5-.5-.9-1.1-1.3-1.7-5-6-9.6-12.4-15.2-17.8-5.8-5.5-12.7-8.4-20.6-9.2-3.8-.4-7.4,0-11,1.1-.6.2-1.2.5-1.8,0,0,0,0,0,0,0s0,0,0,0c-.8-1.9-.7-4-.9-6-.5-5.3-.7-10.7-.1-16,0-.5.1-1,.2-1.5-1.3,2.8-2.2,5.8-3.3,8.9-1.6,4.5-3.8,8.7-7.5,12-2,1.8-4.2,3.3-6.9,4.1t0,0s0,0-.1,0c-.7,0-1.1-.5-1.5-1-3.6-4.6-7.7-8.8-12.2-12.5-.3-.2-.6-.5-.5-1,6.3-8.1,12.5-16.3,18.9-24.3,8-10,17.6-17.9,29.5-22.8,2.9-1.2,6-2.1,9.2-1.9,3.2.4,6.5.2,9.7.1,1.5,0,2.1.6,2,2-.4,3.3-.3,6.6-1.1,9.8-1.8,7.2-2.8,14.5-2.5,22,.2,3.8.9,7.5,2.3,11.2,3.4-4.1,6.8-8.1,10.2-12.1,7.2-8.5,14.4-17.1,21.4-25.8,1.3-1.6,2.7-3.1,3-5.2,11.3,1,22.6,2.4,33.3,6.5,2.4.9,4.8.5,7.2.8,9.3,1.5,15.3,7.1,18.8,15.5,3.4,8,4,16.4,4,24.9Z"
                        fill="#fe6145"
                    />
                    <path
                        d="M132.8,179.8c0,.1,0,.3,0,.4,0,1.2-.7,2.3-1.3,3.4-.4.9-.9,1.8-.8,2.8,0,0,0,0,0,.1,0,0,0,.2,0,.2-2,2.3-2.2,5.3-3.5,7.8,0,.2-.1.4-.2.5-.2.5-.4,1.1-.6,1.7h0c-2.1,6.1-4,12.2-5.8,18.4-5.1,18.2-9.3,36.7-13.2,55.2-.5,2.3-1.1,4.7-1.3,7,0,0,0,0,0,.1,0,0,0,.2,0,.2-2.8,12.1-4.8,24.3-7.2,36.5-.5,2.3-1,4.7-1.4,7-.5,3.2-2.5,5-5.5,5.8-3.3.8-6.6.3-9.8.5h0c0,0-.2,0-.3,0-7.1-.8-14-2.5-20.6-5.1-.9-.3-1.7-.7-2.3-1.5-2.4-1.5-5.1-2.6-7.3-4.5-1.7-1.5-2.1-3.2-1.8-5.3,1.1-7.4,2-14.7,3.2-22.1,1.4-9,2.8-18.1,4.2-27.1,1.4-9.1,3.1-18.2,4.8-27.3,2.3-12.2,4.8-24.3,7.9-36.3,1-3.8,1.8-7.7,3.2-11.4.8-.3,1.4.2,2,.6,7.3,4,15.1,7.2,23,9.8,3.8,1.3,6.2.2,7.9-3.5,1.8-3.9,1.9-8,.4-12-.9-2.3-2.6-4-4.9-5-3.4-1.5-5.7-4.1-6.6-7.7-.8-3-2.6-4.8-5.2-6.1-1.6-.8-3.2-1.5-4.9-2.1-1.3-.4-1.7-1.2-.9-2.4,1.3-1.9,2.6-3.8,4.5-5.2,1.5-1.1,3.1-1.8,5-1.4,7,0,13.3,1.8,19,5.9.3.2.6.5,1,.5,5.4,4,9.8,9,14,14.2.1,0,.2.2.3.3,2,1.9,3.4,4.3,5,6.5,0,.1,0,.3,0,.5Z"
                        fill="#42171a"
                    />
                    <path
                        d="M221.1,84.7c-2.3,2.3-5.4,3.2-8.4,4-.6.2-1.2.3-1.8.6-.6.6-1.1.7-1.2-.3,0-.4.2-.8.3-1.1,1.8-4.6,2.9-9.3,3.2-14.3.1-1.5,1-2.6,2-3.6,2-2,3.1-4.4,3.5-7.2.1-.8,0-1.6-.1-2.4-.4-1.7-1-3.4-2.9-3.9-1.9-.6-3.5.4-4.6,1.7-1.8,2.3-4.2,2.6-6.7,2.8-8.4.6-15.2-2.8-21.2-8.3-3.1-2.9-6.5-5.5-9.4-8.6-.2-.2-.5-.4-.8-.6-2.3-1.8-2.9-1.7-4.6.7-6.3,8.7-10.1,18.2-9.8,29.2,0,1.4,0,2.8,0,4.2-.2,3,.4,6-.3,8.9,0,0-.2.2-.3.2-4-5.9-6.4-12.5-8.7-19.2-3.1-9.1-4.6-18.5-3.6-28,.9-8.4,4.4-15.6,11.8-20.1,5.1-3.1,10.3-6.1,15.9-8.3,7.2-2.8,14.6-4,22.3-2.8,1.2.2,2,0,2.8-1.1,4-5.6,9.5-8.1,16.3-7,7.7,1.3,12.5,6.2,13.9,13.8,1.2,6.3-.8,11.9-5.8,16.2-.8.7-.9,1-.5,1.9,4.3,9,6.6,18.5,7.7,28.3,1.1,9.7-2.5,17.5-9,24.2Z"
                        fill="#42171a"
                    />
                    <path
                        d="M216,111.4c.7,1.6-.2,2.7-1.2,3.9-10.7,13.4-21.9,26.3-32.9,39.5-.2.2-.4.4-.7.7-1.7-3.6-2.4-7.3-2.6-11.1-.5-7.4.4-14.8,2.2-22,.9-3.6.8-7.3,1.3-11,.2-1.2-.3-1.6-1.5-1.6-2.6,0-5.2,0-7.7,0-.6,0-1.3.2-1.9-.4,3.4,0,6.8,0,10.1,0,.7,0,1.7.4,1.8-.9.1-1.2-.5-1.6-1.5-1.9-8.8-2.7-15.6-8.1-21.3-15.1-1.2-1.4-1-3.4-2.3-4.8,0,0,0-.1,0-.2.3-3.2.3-6.3,0-9.5-.1-6,0-12,1.9-17.9,2.1-6.1,5-11.7,9-16.8.7-.9,1.4-1.1,2.5-.5,1.3.7,2.4,1.6,3.5,2.6,3.7,3.9,7.8,7.3,12,10.6,6.1,4.8,13.2,6.2,20.8,4.9.8-.1,1.5-.4,2-1.2.5-.8,1.2-1.4,1.9-2,3-2.3,6.3-1.1,7.1,2.5,1,4.5-.5,8.2-3.7,11.3-.8.8-1.4,1.5-1.4,2.7-.2,5.2-1.4,10.2-3.4,15-.2.5-1,1.4.6.9,1.4,6.5,2.8,13.1,4.2,19.7.2.8-.2,1.9,1,2.4Z"
                        fill="#febaaa"
                    />
                    <path
                        d="M153,379.7c0,0-.2,0-.3,0-.2,0-.4.2-.6.2-5,1.8-10.3,2-15.5,2.5-3.3.3-6.6.8-9.9.2-.3-.1-.7-.2-1.1-.2-.1,0-.3,0-.4,0h-20c-.2,0-.5,0-.8,0,0,0-.1,0-.2,0-.4,0-.7,0-1,.2-3.4.6-6.9,0-10.3-.3-5.1-.4-10.1-.9-15.1-2.3-1.9-.5-3.7-1.3-5.3-2.5t0,0c0,0-.1,0-.2-.1-.2-.2-.3-.5-.4-.7.6-2,1-4.1,2.7-6-2.2.3-4.1.5-5.9.7-.2,0-.4-.2-.5-.3,0,0,0,0,0,0,0,0,0-.1,0-.2,0,0,0,0,0,0,0,0,0,0,0,0,.2-.2.3-.3.5-.4.7-.2,1.4-.4,2.1-.4,3.2-.2,6-1.4,8.3-3.6-6.7,1.5-13.5,1.8-20.3,2.3-8.2.5-16.4.1-24.6.3-.5,0-.9,0-1.4-.1-1.4-.5-2.9-.2-4.4-.2-6.7-.1-13.3-.7-19.8-2.1-2.8-.6-5.6-1.4-8-3.1,0,0,0,0,0,0h0c-.1-.2-.2-.3-.1-.5,0,0,0,0,0-.1,1.4-7.8,7.7-10.2,14-12.6,1.9-.7,4-1.2,6.1-1.5,1.7-.3,3.6-.9,5.4-1.5,1.1-.4,2,0,3,.1.9.2.2-.5.2-.7,0,0,0,0,.2,0,2.1,0,4.2-.8,6.3-.4.3,0,.8.6.9-.3,3.9.1,7.3-.9,10.6-3.1,1.9-1.3,4.2-1.4,6.4-1,1.5,0,2.9.8,4.3,1.3,4.9,2.1,9.7,2.1,14.5-.5,2-1.1,3.9-2.3,6.4-1.5,0,0,0,0,0,0,0,0,.1,0,.1,0,1.8.9,2.6,2.5,3.2,4.3,1.8,5.4,1.9,10.9.7,16.4-.6,3-.6,3,2.4,2.1,2.2-.7,4.3-1.3,6.7-1.6,1.6-.2,3.4-1,5.1-1.5.8,0,1.6-.2,2.3,0,.2,0,.4.1.7.2.1,0,.2.1.3.2.1,0,.2.2.4.3,0,0,0,0,0,0,0,0,.1,0,.2,0,0,0,0,0-.1-.1,0,0,0,0-.1-.1-.1-.1-.2-.2-.2-.4,0,0,0,0,0,0,0,0,0,0,0,0,0-.1.2-.3.3-.4,2.4-.5,4.8-1.7,7.2,0,0,0,.1.6.2,0,.2-1.2,1.4-1.2,2.2-1.2,2.8.1,5-1.1,7.2-2.4,2-1.1,4.1-1.9,6.4-1.5,0,0,.1,0,.2,0,.2,0,.4.1.6.2,1.3.3,2.5.8,3.7,1.4,5.2,2.5,10.3,2.6,15.4-.2,1.1-.6,2.2-1.2,3.4-1.5,1-.3,1.8-.2,2.6.4.5.5,1.3.8,1.5,1.6,3.7,7.4,2.9,14.9,1,22.5Z"
                        fill="#42171a"
                    />
                    <path
                        d="M218.4,358.5c-3.5,1.1-7,1-10.6.9-11.5-.2-22.7-1.8-33.1-6.9-6-2.9-11.5-6.6-16.7-10.7-.3-2.3.7-4.4,1.3-6.5,5.1-18.5,10.4-37.1,15.6-55.6.2-.7.5-1.4,1.1-2.2.8,3.3,1.6,6.6,2.4,9.9,1.9,7.9,3.9,15.9,5.8,23.8,2.4,10,5.2,19.8,10,28.9,3.6,6.8,8.4,12.5,15.6,15.8,2.8,1.3,5.7,1.8,8.6,2.5Z"
                        fill="#2e151d"
                    />
                    <path
                        d="M104.8,196.6c-1.5,1.5-3.2,2.3-5.4,1.6-9.1-2.8-17.9-6.5-26.3-11.3-.1-.2-.1-.6-.3-.7-3.5-2.1-6.6-4.8-9.7-7.4-2.4-2-3.8-4.2-4-7.1-.5-5.8-.3-11.4,4.4-15.7.9-.8,1.9-1.7,3-2.2,4.3-2,8.7-4.4,13.6-4.5,4.5,0,9.1.3,13.2,2.7-4.4.5-6.9,3.5-9.2,6.8-.7,1-.5,1.4.6,1.8,1.2.4,2.5.9,3.7,1.4,3.6,1.5,6.3,3.7,7.2,7.9.6,3,2.8,5.3,5.8,6.3,8.5,3,7.7,16.1,3.4,20.4Z"
                        fill="#febaaa"
                    />
                    <path
                        d="M259.3,276.5c-3-2.9-5.6-6.2-8.2-9.4-7.4-9.1-14.7-18.3-22-27.5-2.9-3.6-5.6-7.3-8.5-11,0,0,.2-.1.2-.2.5-.3,1-.3,1.5-.2,6.2,1.2,12.4,1.8,18.7,2,.2,0,.5.2.6.3,0,0,0,0,.1.1.2.2.3.4.4.7h0c3.4,1.7,6.9,2.9,10.7,2.8.9,9.8,2.1,19.6,3.9,29.2.8,4.3,1.6,8.6,2.4,12.9Z"
                        fill="#981e08"
                    />
                    <path
                        d="M81.7,330.6c-.7,3.5-1.1,7.1-2.3,10.6,0,.1,0,.2-.1.4-1.6-.4-3.1-.2-4.5.6-1.6.9-3.3,1.7-5,2.3-4.3,1.5-8.4.5-12.4-1.2-1.1-.5-2.1-1-3.2-1.5.9,0,1-.8,1.1-1.4.9-6.5,2.5-12.9,3.7-19.3,7.4,3.2,15.1,5.1,23.1,6.1,0,.2,0,.3.1.5.2,1-.2,2-.4,3Z"
                        fill="#febaaa"
                    />
                    <path
                        d="M150.5,355.6c-2.1-.6-3.9.4-5.6,1.4-3,1.7-6.2,2.6-9.7,2.3-2-.2-3.9-.8-5.7-1.5-1.7-.7-3.3-1.6-5.1-2,.1-.1.2-.2.3-.3.6-.7,1-1.6,1.2-2.6.5-2.3.8-4.6,1.9-6.7,0-.1.2-.3.2-.4,5.4,3.3,11,6.4,17.3,7.7,2.1.4,4.1.7,6.2.3-.6.5-1.2,1-1.1,1.9Z"
                        fill="#febaaa"
                    />
                    <path
                        d="M153.2,380.6c-.5,2-1.7,3.2-3.7,3.7-3.5.8-6.9,1.4-10.5,1.3-.4.2-.6,0-.7-.3,0,0,0,0,0,0,0,0,0,0,0,0,.9-.9,2.2-.8,3.3-1,2.2-.3,4.3-.7,6.5-1.1,1.7-.3,3.2-.9,3.8-2.8,0-.1.1-.2.2-.3.2-.2.4-.4.7-.5,1.8-7.2,2.7-14.3-.6-21.4-.1-.3-.1-.6-.2-1,2,2.3,2.3,5.1,2.6,7.9.6,5.2-.2,10.4-1.5,15.5Z"
                        fill="#411a1e"
                    />
                    <path
                        d="M56.5,372.3c-1.3,0-2.6,0-3.9.1-1.8.4-3.7,0-5.5.2-.2,0-.3,0-.5,0h-7.9c-2-.4-4,.1-6-.3-1.2,0-2.3,0-3.5-.1,0-.4.2-.6.5-.7.1,0,.2,0,.3,0,0,0,.1,0,.2,0,1.4-.1,2.9,0,4.3-.1,5.4,0,10.8,0,16.2,0,1.3.1,2.6,0,3.9,0,.5,0,1,0,1.5.2h0c0,0,.1,0,.2,0,0,0,0,0,0,0,0,0,.1.1.1.2,0,0,0,0,0,0,0,0,0,.2,0,.3Z"
                        fill="#26131d"
                    />
                    <path
                        d="M84.8,384.4c0,0,0,.2,0,.2,0,0,0,0,0,0,0,.3-.3.2-.6.3h0c0,0-.2,0-.2.1-3.6-.3-7.1-1-10.3-2.5-2.7-1.3-3-2.7-1.5-5.8.1.2.3.3.4.5,0,0,0,.1.1.2,0,0,0,.1,0,.2.4,3.5.5,3.6,4.2,4.7,2,.6,4,.9,6,1.3.7.1,1.5,0,2,.7Z"
                        fill="#3e161a"
                    />
                    <path
                        d="M138.1,385.9c-5,.3-10.1.5-15.1.8,0-.1-.1-.2-.1-.4,0,0,0,0,0-.1s0,0,0-.1c0,0,0,0,0,0,.2-.2.3-.3.6-.4,4.2-.2,8.3-.7,12.5-.6.1,0,.2,0,.4,0,.4,0,.8,0,1.2.2.2.1.4.3.6.7Z"
                        fill="#41191d"
                    />
                    <path
                        d="M30,372c-.3.1-.6.2-.9.4-4,.1-8-.4-11.9-.8.1-.4.4-.7.6-.8h0c.4-.1.8,0,1.2-.1,0,0,.2,0,.3,0,3.5,0,6.9.2,10.4.7.1,0,.2.1.2.2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,.2,0,.3Z"
                        fill="#35141b"
                    />
                    <path
                        d="M123,386.7h-17.9c0-.4.2-.6.5-.7.1,0,.2,0,.3,0,0,0,0,0,.1,0,.7,0,1.4,0,2.1,0,3.2,0,6.3,0,9.5,0,.8,0,1.7,0,2.5,0,.8,0,1.6,0,2.3.2,0,0,.1,0,.2,0,0,0,.1,0,.2.1,0,0,0,0,.1.1,0,.1,0,.2,0,.4Z"
                        fill="#15101e"
                    />
                    <path
                        d="M92.4,385.3c0,0,0,0,0,.2,0,0,0,0,0,0-.5.5-1.3,0-1.9.4-1.7.1-3.3-.3-4.9-.7,0-.2.1-.3.2-.5,0,0,0,0,.1-.1,0,0,.2-.1.3-.2,2.1-.1,4.3-.3,6.3.8Z"
                        fill="#512022"
                    />
                    <path
                        d="M64.2,371.6h0c0,.1,0,.2,0,.3-1,0-2,0-3,0-1.1.4-2.3,0-3.4.3h-1.2c0,0,0-.2,0-.3,0,0,0,0,0,0,0,0,0-.1.1-.2,0,0,0,0,.1-.1.2-.2.5-.3.8-.4,1.2-.3,2.4-.2,3.6-.2.9,0,1.7,0,2.5.2h0c0,0,.1,0,.2,0,0,0,.2,0,.2.2,0,0,0,0,0,0,0,0,0,0,0,0t0,0Z"
                        fill="#41191e"
                    />
                    <path
                        d="M105.9,386h0c0,.1,0,.2,0,.3-.3.1-.6.2-.8.4h0c-1.2,0-2.5.3-3.6-.3,0-.2.2-.3.3-.4,0,0,.2-.1.3-.2.1,0,.3-.1.4-.2,1.1-.2,2.2-.3,3.3.1,0,0,.1.1.2.2,0,0,0,0,0,0Z"
                        fill="#4d1716"
                    />
                    <path
                        d="M19.3,371.2c-.7.3-1.5-.2-2.1.4-1.1.3-2.1-.4-3.2-.4-.2-.3-.2-.5,0-.7h0c0,0,.1-.1.2-.2,1.2-.2,2.4,0,3.6,0,.5,0,1,0,1.3.4s.2.3.3.5Z"
                        fill="#601711"
                    />
                    <path
                        d="M102.3,386c-.3.1-.6.3-.8.4-2,0-3.9,0-5.9-.1.2-.4.4-.6.6-.7.5-.3,1.2,0,1.8-.1.2,0,.3,0,.4,0,1.2.1,2.5-.3,3.6.4,0,0,.2.1.3.2Z"
                        fill="#3c1a20"
                    />
                    <path
                        d="M8.9,369.7c0,.1,0,.2,0,.3,0,0,0,0,0,0-1.5.2-2.9-.5-4.3-.9,0-.2,0-.4.2-.5,0,0,.1,0,.2,0,0,0,0,0,.1,0,1.5,0,2.8,0,3.9,1.2Z"
                        fill="#3d181d"
                    />
                    <path
                        d="M52.6,372.5c-2,.6-4,.3-6,.3.2,0,.3,0,.5,0,1.8-.4,3.7-.3,5.5-.2Z"
                        fill="#d93015"
                    />
                    <path d="M38.7,372.8c-2,0-4,.3-6-.3,2,0,4-.2,6,.3Z" fill="#d83015" />
                    <path
                        d="M98.3,385.9c-.9.3-1.9-.2-2.8.4-.6,0-1.2.2-1.6-.2,0-.2.2-.3.3-.5,0,0,.2-.1.2-.2.1,0,.3-.1.5-.2,1,0,2.2-.6,3,.2.1.1.2.3.3.4Z"
                        fill="#421a1f"
                    />
                    <path
                        d="M.9,366c0,0,0,0,0,.1,0,0,0,0,0,.1-.1.1-.2.2-.4.3-.7-1.3-.4-2.5.4-3.6,0,0,0,0,0-.1,0,.2,0,.4,0,.5,0,0,0,0,0,0,.6.9.3,1.7,0,2.6Z"
                        fill="#643b3e"
                    />
                    <path
                        d="M3,367.8c0,0,0,.1,0,.2,0,0,0,0,0,0,0,.2-.2.3-.4.3-.8-.4-1.8-.7-2.1-1.8,0-.2.1-.5.2-.7.1,0,.2,0,.3.1.8.4,1.5,1.1,2.1,1.8Z"
                        fill="#652e2d"
                    />
                    <path
                        d="M14,371.2c-.9-.1-1.9-.3-2.8-.4-.2-.2-.2-.5,0-.7,0,0,0,0,0,0,0,0,0,0,.1-.2,1-.4,1.9-.2,2.7.6h0c0,.3,0,.5,0,.7Z"
                        fill="#481e21"
                    />
                    <path
                        d="M68.9,371.6c-.7,0-1.5,0-2.2,0h0c-.8.2-1.7.3-2.5,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0-.2,0-.2h0c0-.1,0-.2.2-.3.7-.4,1.5-.3,2.3-.4.4,0,.9,0,1.3.2.1,0,.2,0,.3.1,0,0,.2.1.3.2,0,0,0,0,0,0,.1.1.3.2.4.3Z"
                        fill="#451c21"
                    />
                    <path
                        d="M94.7,385.6c-.3.2-.5.3-.8.4-1.2,0-2.3,0-3.5-.1.5-.6,1.3-.2,1.9-.4,0,0,.1,0,.2,0,.7-.5,1.4-.5,2.1,0,0,0,.2.1.2.2Z"
                        fill="#3c1a20"
                    />
                    <path d="M61.2,372c-1.1.6-2.3.3-3.4.3,1.1-.7,2.3-.2,3.4-.3Z" fill="#581511" />
                    <path
                        d="M38,347.5c-.7-.5-1.2-.9-1.8-1.3.3-.1.6-.2.9-.3.2.5.9.7.9,1.6Z"
                        fill="#fea493"
                    />
                    <path
                        d="M5,368.7c-.2.1-.3.3-.5.4-.7-.3-1.3-.5-2-.8.2-.1.3-.2.4-.3,0,0,0,0,0,0,.8-.3,1.5-.2,1.9.6,0,0,0,.1,0,.2Z"
                        fill="#461a1c"
                    />
                    <path
                        d="M139.1,385.6c-.2.4-.6.4-1,.3-.6-.6-1.5-.2-2.1-.4.1-.2.3-.4.4-.5.6-.6,1.4-.4,2.1.2,0,0,0,0,0,0,.2.1.4.2.6.4Z"
                        fill="#5e160f"
                    />
                    <path
                        d="M31,348.5c-.8,0-1.1-.6-1.5-1.1,0-.3,0-.6.2-.7,0,0,.1,0,.2,0-.2.9.8,1.1,1,1.8Z"
                        fill="#fea491"
                    />
                    <path d="M8.8,370s0,0,0,0c0,0,0,0,0,0h0Z" fill="#3d181d" />
                    <path
                        d="M11.2,370.1v.7c-.7-.6-1.8.1-2.3-.7,0,0,0,0,0,0,.8-.6,1.5-.7,2.3,0,0,0,0,0,0,0Z"
                        fill="#3d181d"
                    />
                    <path
                        d="M66.7,371.6c-.8.5-1.7.4-2.5.3v-.3h0c.8,0,1.7,0,2.5,0Z"
                        fill="#531512"
                    />
                    <path
                        d="M86,384.8c-.2.1-.3.3-.5.4-.5,0-1,0-1.4-.1,0,0,.2,0,.2-.1h0c.2,0,.4-.2.6-.3,0,0,0,0,0,0,.3-.2.6-.2.9,0,0,0,0,0,.1.1Z"
                        fill="#17101e"
                    />
                    <path
                        d="M241.4,230.8c-7,.3-13.9-.9-20.8-2.1-6.1-1.8-12.3-3.4-18.4-5.3-14.3-4.4-28.5-8.7-42.9-12.7-3-.8-6.1-1.1-9.2-1.3-6.2-.3-12.1-1.7-18-3.4-2,.2-3.4-.8-4.2-2.3-1.5-2.9-2.1-5.9-.8-9.1.7-2.9,1.2-5.8,3.6-8,.4-1,1.3-.9,2.1-1,1.3,0,2.6.1,3.8-.4,1.2-.7,2-1.7,2.7-2.8.9-1.5,2.1-2,3.8-2,4.7,0,8.4,2,11.4,5.5.8.9,1.6,1.8,2,3,1.2,3.9,4.2,4.9,7.8,5.6,6.6,1.3,13.2,2.7,19.9,3.6,3.3.4,6.6.7,9.9.7.9,0,1.9-.2,2.7.5,11.9-.4,23.7-1.2,35.6-1.2,1.7-1,3.5-.6,5.2-.5,2.8.2,5.6.6,8.4,1.1,1.4.3,2.9.5,3.8,1.9,5.1,2.6,8.6,6.5,10,12.1,1.6,6.4-1.4,12.4-7.4,15.3-2.5,1.2-5.1,1.8-7.8,2.4-1.1.3-2.1.5-3.2.4Z"
                        fill="#febaaa"
                    />
                    <path
                        d="M121.7,301.7c-4.4-5.6-8-11.6-11.9-17.5-1.4-2.2-2.7-4.4-4.1-6.7,0-3.5,1.1-6.7,1.8-10.1,2.1-10,4.3-20,6.7-29.9,3.4-13.9,7-27.8,11.8-41.3.3-.8.4-1.6.7-2.6.1.4.2.7.3,1,0,1.9-.1,3.8.2,5.7.4,3,2,4.9,4.9,5.7-5.1,31.7-7.1,63.7-10.4,95.6Z"
                        fill="#2e151d"
                    />
                    <path
                        d="M196.7,199.3c-12.7.3-24.9-2.3-37.1-5.4-1.2-.3-1.7-.9-2.2-2-2.1-4.9-5.2-8.8-10.6-10.5-.8-.2-1.7-.3-2.5-.5-2.3-.4-4.1,0-5.1,2.5-.4,1-1.1,2-2.3,2.3-1-.5-.5-1.4-.5-2.2,1.1-3.6,2.5-7,5.4-9.5,1.4-1.2,2.8-2.4,4.7-2.6,11.7-3.9,22.2-1.1,31.8,6.1,3.8,2.8,6.6,6.7,9.6,10.3,2.9,3.4,5.7,7,8.5,10.4.3.3.5.6.4,1Z"
                        fill="#42171a"
                    />
                    <path
                        d="M146.5,171.5c-2.5,1.5-4.9,3-6.6,5.4-1.2,1.7-2,3.5-2.6,5.5-.2.6-.1,1.2-.8,1.4-1.8-1-3.3-2.3-4-4.3-1.9-2.1-3.6-4.5-5.1-6.9,7.1-2.7,11.3-8,13.9-14.9,1.2-3.2,2.1-6.6,3.6-9.7.3-.6.6-1.1,1.1-1.7-1.1,8.4-.4,16.8.6,25.1Z"
                        fill="#981e08"
                    />
                    <path
                        d="M249.8,200.6c-5.6-2.5-11.5-2.3-17.4-2.5-.2-6.1.4-12.1.6-18.2.2-5.1.3-10.3.5-15.4,0-1,0-2,.3-2.9.5,4.5.9,9,1.4,13.5.6,6.3,1.2,12.6,2.4,18.8.5,2.5,1.7,3.5,4.6,3.8,1.9.2,3.8.4,5.7.9.9.2,2.1.5,2,2Z"
                        fill="#981e08"
                    />
                    <path
                        d="M241.4,230.8c1-.5,2.1-.4,3.2-.4-.8.4-1.6.8-2.4,1.2-.4,0-.7-.4-.8-.8Z"
                        fill="#ed391c"
                    />
                    <path
                        d="M132.5,179.5c1.3,1.4,2.7,2.9,4,4.3.4.6-.2,1.4.4,1.9-.9.8-2,.5-3,.5-1.1,0-2.2-.3-3.2.4-1-.8-.3-1.5,0-2.3.7-1.6,1.6-3.1,1.8-4.9Z"
                        fill="#2f151d"
                    />
                    <path d="M158,77.1c.3,3.2.7,6.3,0,9.5,0-3.2,0-6.3,0-9.5Z" fill="#dd3114" />
                    <path
                        d="M80.4,366.6c-3.5,2.9-7.5,4.1-12,4.3,0,0,0,0-.1,0,0,0-.2,0-.2,0-.6.1-1.3,0-1.9.2-.7,0-1.4,0-2.2,0,0,0,0,0,0,0,0,0-.1,0-.2,0-.9.2-1.8-.1-2.7.2-1.2,0-2.5,0-3.8,0-.2,0-.4.1-.7.1,0,0-.2,0-.3,0-.5,0-.9,0-1.4,0h0c-1.4,0-2.7-.2-4.1.1h-16.5c-1.4-.3-2.9,0-4.4-.2,0,0-.1,0-.2,0,0,0-.1,0-.2,0-3.4-.2-6.9-.3-10.3-.5-.5-.2-1-.3-1.5-.3h0c-1.3-.1-2.6.1-3.8-.3,0,0,0,0,0,0-.9-.1-1.8-.3-2.7-.4,0,0,0,0,0,0-.8-.2-1.5,0-2.3,0,0,0,0,0,0,0h0c-1-1.2-2.5-.9-3.8-1.3,0,0-.2,0-.2,0-.6-.2-1.2-.4-1.7-.7t0,0c-.7-.6-1.7-.9-2.2-1.7,0-.1-.1-.2-.2-.4.1-.8.2-1.6.3-2.5,0,0,0,0,0,0,.2-.1.6-.4.7-.3,4.3,3.2,9.5,3.4,14.5,4.1,4.2.6,8.4,1,12.7,1,1.5.4,3-.1,4.4.3,11.7.2,23.3.3,34.9-.8,4.1-.4,8.3-.5,12.2-2.2.2-.1.8,0,.9,0,.3.6-.3.8-.7,1.2Z"
                        fill="#fefdfd"
                    />
                    <path
                        d="M85.9,384.8c-.4,0-.7,0-1.1-.1-3.2-.8-6.5-1-9.5-2.2-3.6-1.4-3.7-1.5-2.7-5.1,9.7,4.7,20.2,4.6,30.5,5,.3-.2.7-.2,1,0,3,.2,5.9,0,8.9,0,1.4,0,2.9,0,4.3,0,3,0,5.9,0,8.9,0,.2,0,.5,0,.7,0,8.6-.2,17.2-.4,25.5-3,.1,0,.3.1.4.2-.8,4.2-4.3,4.1-7.3,4.7-2.3.5-4.7.6-7,.9-.9,0-1.7-.1-2.5.3-4.3.2-8.6.3-12.9.5-.9.3-1.9,0-2.8.1-.8,0-1.7,0-2.5,0-3.2,0-6.4,0-9.6,0-.7,0-1.5.1-2.2-.1-1.2,0-2.4,0-3.5,0-1.3,0-2.6,0-4-.1-1.2-.4-2.4-.3-3.6-.3-.8,0-1.5,0-2.3-.1-2.1-.4-4.3-.6-6.5-.7Z"
                        fill="#fefdfd"
                    />
                    <path
                        d="M117.4,382.7c-1.4,0-2.9,0-4.3,0-2.4-.4-4.7,0-7.1-.2-.7,0-1.5.1-2.2-.1-.2,0-.4,0-.7,0,.4-.5,1-.4,1.5-.4,7,0,13.9,0,20.9,0,.5,0,1.1-.1,1.5.4,0,0-.1,0-.1,0h-.2c-2.4.3-4.7,0-7.1.1-.7,0-1.5-.1-2.2.2Z"
                        fill="#33161c"
                    />
                    <path
                        d="M95.7,370.6c-.1-1.1-.3-2.1-.4-3.2.2,0,.3,0,.5,0,.2,1.1.5,2.1,0,3.3Z"
                        fill="#f57962"
                    />
                    <path
                        d="M32.5,354.3c-.1-.9-.3-1.8-.4-2.7.8.8.9,1.7.9,2.6-.2,0-.3,0-.5,0Z"
                        fill="#fb8b76"
                    />
                    <path
                        d="M97.5,361.3c1.1-.8,2.4-.2,3.6-.4,0,.1,0,.2,0,.3.2.2.1.4,0,.5-1.1-.8-2.4-.4-3.6-.5Z"
                        fill="#feefea"
                    />
                    <path d="M33.1,368.4c-1.5,0-3,.2-4.4-.3,1.5,0,3-.3,4.4.3Z" fill="#fa482b" />
                    <path
                        d="M111.4,366c.4,1,.6,2,.2,3.4-.2-1.4-.4-2.4-.5-3.4,0-.2.2-.2.3,0Z"
                        fill="#feb09e"
                    />
                    <path d="M39.6,351.6c.9.7.6,1.7.4,2.7-.1-.9-.3-1.8-.4-2.7Z" fill="#feb4a4" />
                    <path d="M103.6,366.3c.9.8.7,1.8.5,3.2-.5-1.2,0-2.3-.5-3.2Z" fill="#feb6a6" />
                    <path
                        d="M24.3,354.1c.5.7.5,1.4.3,2.5-.5-.9-.6-1.6-.6-2.4,0-.2.2-.2.3,0Z"
                        fill="#fea996"
                    />
                    <path
                        d="M101.1,361.8c0-.2,0-.4,0-.5.5.4,1,.7,1.1,1.4-.7,0-.9-.5-1.2-.9Z"
                        fill="#feb0a0"
                    />
                    <path
                        d="M66.3,371.2c.7-.4,1.4-.4,2.2-.3,0,.1,0,.2,0,.3-.7,0-1.5,0-2.2,0Z"
                        fill="#551714"
                    />
                    <path
                        d="M24.3,354.1c-.1,0-.2,0-.3,0,0-.5-.2-.9-.3-1.6.8.4.6,1.1.6,1.6Z"
                        fill="#feb2a1"
                    />
                    <path
                        d="M111.4,366c0,0-.2,0-.3,0-.1-.5-.2-.9-.4-1.5.8.4.8,1,.7,1.5Z"
                        fill="#febcac"
                    />
                    <path
                        d="M94.7,365.3c.6.2.8.8.6,1-.4.4-.5-.2-.6-.5,0-.2,0-.3,0-.6Z"
                        fill="#fc937e"
                    />
                    <path
                        d="M29.8,371.7c1.5,0,3.1-.2,4.6.3-1.5,0-2.9,0-4.4,0,0-.1-.1-.2-.2-.3Z"
                        fill="#f84e30"
                    />
                    <path
                        d="M50.9,371.9c1.4-.4,2.8-.3,4.2-.3,0,.2,0,.3-.2.3-1.3,0-2.6,0-4,0Z"
                        fill="#f94f32"
                    />
                    <path
                        d="M54.9,372c0,0,.1-.2.2-.3.8,0,1.5,0,2.3,0-.3.1-.5.2-.8.4-.6,0-1.1,0-1.7,0Z"
                        fill="#581511"
                    />
                    <path
                        d="M120.2,386c.9,0,1.9,0,2.8,0,0,0,0,.2,0,.3-.9,0-1.9,0-2.8,0,0,0,0-.2,0-.3Z"
                        fill="#d52f14"
                    />
                    <path
                        d="M107.8,386c3.3,0,6.6,0,10,0,0,0,0,.2,0,.3-3.3,0-6.6,0-9.9,0-.1,0-.2-.1-.2-.2,0,0,0-.1.1-.1Z"
                        fill="#fe4f32"
                    />
                    <path
                        d="M117.8,386.3c0,0,0-.2,0-.3.8,0,1.6,0,2.4,0,0,0,0,.2,0,.3-.8,0-1.6,0-2.4,0Z"
                        fill="#fe4e31"
                    />
                    <path
                        d="M107.8,386c0,0,0,.2,0,.3-.7,0-1.3,0-2,0,0-.1,0-.2,0-.3.7,0,1.3,0,2,0Z"
                        fill="#d42f14"
                    />
                    <path
                        d="M61.1,371.6c1-.5,2-.4,3-.3,0,.1,0,.2,0,.3-1,0-2,0-3,0Z"
                        fill="#531512"
                    />
                    <path d="M117.4,382.7c3.1-.6,6.2-.4,9.3-.3-3.1.5-6.2.4-9.3.3Z" fill="#d62f13" />
                    <path d="M103.8,382.4c3.1,0,6.2-.2,9.2.3-3.1,0-6.2.3-9.2-.3Z" fill="#ea3c20" />
                </g>
                <g id="LARMES">
                    <path
                        d="M234.1,70.1c-.5-.7.4-.9.6-1.4,2-3.6,5-6.2,8.3-8.3,3.7-2.4,7.8-2.3,11.7-.6,1.2.5,1.6,1.9,1.4,3-.2,1.4-.7,2.8-2.3,3.3-1.3.4-2.6.9-4,.7-1.2-.2-2.5.3-3.7.2-2.5.6-5.1.9-7.5,1.9-.9.4-1.8.9-2.7,1.2-.6.2-1.3.5-1.8-.2Z"
                        fill="#fefefe"
                        className={styles.tear}
                    />
                    <path
                        d="M255.6,76.1c1.1,1.4,2.4,2.9,2.2,4.6,0,1.6-.1,2.6-1.6,3.2-1.5.7-2.9.4-4.2-.3-2.8-1.4-4.6-3.9-7-5.7-1.2-1-2.6-2.3-4.6-2.5-1.5-.2-2.6-1.8-4.2-1.9-.4,0-.4-.5-.2-.8.1-.3.4-.5.8-.5,3.8,0,7.7-.5,11.5.2,2.8.5,5.5,1.5,7.3,3.8Z"
                        fill="#fefefe"
                        className={styles.tear}
                    />
                    <path
                        d="M256.1,62.8c-.2,1.4-.7,2.8-2.3,3.3-1.3.4-2.6.9-4,.7-1.2-.2-2.5.3-3.7.2-2.5.6-5.1.9-7.5,1.9-.9.4-1.8.9-2.7,1.2-.6.2-1.3.5-1.8-.2-.5-.7.4-.9.6-1.4,2-3.6,5-6.2,8.3-8.3,3.7-2.4,7.8-2.3,11.7-.6,1.2.5,1.6,1.9,1.4,3Z"
                        fill="#fee1da"
                        className={styles.tear}
                    />
                    <path
                        d="M257.8,80.7c0,1.6-.1,2.6-1.6,3.2-1.5.7-2.9.4-4.2-.3-2.8-1.4-4.6-3.9-7-5.7-1.2-1-2.6-2.3-4.6-2.5-1.5-.2-2.6-1.8-4.2-1.9-.4,0-.4-.5-.2-.8.1-.3.4-.5.8-.5,3.8,0,7.7-.5,11.5.2,2.8.5,5.5,1.5,7.3,3.8,1.1,1.4,2.4,2.9,2.2,4.6Z"
                        fill="#fee1da"
                        className={styles.tear}
                    />
                    <path
                        d="M118.4,80.5c.9-3.6,5.2-7.5,9-8,2.1-.3,4.2-.7,6.5-.4,1.6,0,3.3,0,5.1,0,.5,0,.9.2,1.1.7.2.9-.5.5-.8.6-2.6,1-5.4,2-7.3,3.8-1.9,1.8-4.7,2.4-5.7,5.2-.5,1.3-4.3,2.2-5.8,1.8-1.7-.6-2.5-1.9-2-3.7Z"
                        fill="#fefefe"
                        className={styles.tear}
                    />
                    <path
                        d="M120.2,63.9c-.4-2.1.8-4.4,2.7-4.7,1.3-.2,2.5-1.1,4-.7,2.9.5,5.5,1.6,8.1,3.3,2.8,1.8,3.9,4.9,6.5,6.7.5.3.7.9.4,1.4-.3.6-.8.4-1.4.2-2.3-.9-4.6-1.7-7-2.5-3-1-6.2-1-9.4-1.1-1.9,0-3.7-1.1-4-2.8Z"
                        fill="#fefefe"
                        className={styles.tear}
                    />
                    <path
                        d="M141.9,70c-.3.6-.8.4-1.4.2-2.3-.9-4.6-1.7-7-2.5-3-1-6.2-1-9.4-1.1-1.9,0-3.7-1.1-4-2.8-.4-2.1.8-4.4,2.7-4.7,1.3-.2,2.5-1.1,4-.7,2.9.5,5.5,1.6,8.1,3.3,2.8,1.8,3.9,4.9,6.5,6.7.5.3.7.9.4,1.4Z"
                        fill="#fee1da"
                        className={styles.tear}
                    />
                    <path
                        d="M139.1,73.4c-2.6,1-5.4,2-7.3,3.8-1.9,1.8-4.7,2.4-5.7,5.2-.5,1.3-4.3,2.2-5.8,1.8-1.7-.6-2.5-1.9-2-3.7.9-3.6,5.2-7.5,9-8,2.1-.3,4.2-.7,6.5-.4,1.6,0,3.3,0,5.1,0,.5,0,.9.2,1.1.7.2.9-.5.5-.8.6Z"
                        fill="#fee1da"
                        className={styles.tear}
                    />
                </g>
            </svg>
        </div>
    );
}

// eslint-disable-next-line @eslint-react/no-prop-types
Crying.propTypes = propTypes;

export default Crying;
