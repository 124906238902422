import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../../styles/animations/jeu-aide-machine/dices.module.css';

const propTypes = {
    className: PropTypes.string,
};

function Dices({ className }) {
    return (
        <div className={classNames([styles.container, className])}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 349.6 275.4">
                <g className={styles.dice} id="DICE.1">
                    <g>
                        <polyline
                            points="20.2 87.4 47.8 160.4 121.4 168.2 94.6 99.3 121.4 168.2 168.2 110.7 139.9 41.2"
                            fill="#cbc9e6"
                        />
                        <polygon
                            points="20.2 87.4 94.6 99.3 139.9 41.2 68 29.5 20.2 87.4"
                            fill="#fff"
                        />
                    </g>
                    <path
                        d="M125.1,148.2c-4,0-7.3-3.3-7.4-7.3,0-4.1,3.4-7.4,7.5-7.4,4,0,7.3,3.4,7.2,7.4,0,4-3.3,7.3-7.4,7.3Z"
                        fill="#000"
                    />
                    <path
                        d="M103,102.7c0-4.1,3.2-7.3,7.3-7.2,4,0,7.3,3.3,7.3,7.3,0,4.1-3.4,7.4-7.5,7.3-4,0-7.2-3.3-7.2-7.4Z"
                        fill="#000"
                    />
                    <path
                        d="M130.4,113.1c-4.1,0-7.3-3.2-7.3-7.2,0-4.1,3.3-7.5,7.4-7.4,4,0,7.3,3.3,7.2,7.4,0,4-3.3,7.2-7.3,7.2Z"
                        fill="#000"
                    />
                    <path
                        d="M143.1,70.8c0,4.1-3.2,7.5-7.4,7.4-4,0-7.3-3.3-7.3-7.3,0-4,3.2-7.3,7.3-7.3,4.1,0,7.3,3.1,7.4,7.2Z"
                        fill="#000"
                    />
                    <path
                        d="M70.4,136.7c-4.1,0-7.4-3.2-7.4-7.3,0-4.1,3.2-7.3,7.3-7.4,4.1,0,7.4,3.2,7.4,7.3,0,4-3.2,7.3-7.3,7.4Z"
                        fill="#000"
                    />
                    <path
                        d="M150.6,116.2c-4.1,0-7.3-3.3-7.2-7.4,0-4.1,3.3-7.3,7.4-7.3,4.1,0,7.3,3.4,7.3,7.4,0,4.1-3.3,7.3-7.4,7.2Z"
                        fill="#000"
                    />
                    <path
                        d="M107.5,57.7c0,4.1-3.3,7.3-7.4,7.2-4,0-7.2-3.3-7.2-7.3,0-4.1,3.2-7.3,7.3-7.3,4.1,0,7.3,3.2,7.2,7.3Z"
                        fill="#000"
                    />
                    <path
                        d="M59.8,66.2c4.1,0,7.3,3.2,7.3,7.3,0,4-3.2,7.2-7.2,7.2-4,0-7.4-3.2-7.4-7.2,0-4.1,3.2-7.3,7.3-7.3Z"
                        fill="#000"
                    />
                </g>
                <g className={styles.dice} id="DICE.2">
                    <polygon
                        points="269.2 103.7 254.5 176.5 183.7 199.4 202.1 124.5 269.2 103.7"
                        fill="#fff"
                    />
                    <polygon
                        points="325 151.5 309.4 226.3 238.9 249.7 183.7 199.4 254.5 176.5 269.2 103.7 325 151.5"
                        fill="#cbc9e6"
                    />
                    <path
                        d="M245.8,218.1c-4.1,0-7.3-3.2-7.3-7.3,0-4.2,3.2-7.4,7.4-7.4,4,0,7.3,3.4,7.2,7.4,0,4.1-3.3,7.3-7.3,7.3Z"
                        fill="#000"
                    />
                    <path
                        d="M282,149.3c-4.1,0-7.2-3.2-7.2-7.3,0-4.1,3.2-7.3,7.3-7.4,4.1,0,7.5,3.4,7.4,7.5,0,4.1-3.3,7.2-7.4,7.2Z"
                        fill="#000"
                    />
                    <path
                        d="M242.6,137.2c-4,0-7.3-3.3-7.3-7.3,0-4,3.3-7.3,7.4-7.2,4,0,7.2,3.3,7.2,7.2,0,4-3.3,7.3-7.3,7.4Z"
                        fill="#000"
                    />
                    <path
                        d="M303,183.4c0,4-3.3,7.3-7.3,7.3-4.1,0-7.3-3.3-7.3-7.4,0-4.1,3.2-7.3,7.3-7.3,4.1,0,7.3,3.2,7.3,7.3Z"
                        fill="#000"
                    />
                    <path
                        d="M243.5,160c0,4.1-3.2,7.4-7.3,7.3-4,0-7.3-3.4-7.3-7.4,0-4,3.3-7.2,7.2-7.2,4,0,7.3,3.2,7.3,7.3Z"
                        fill="#000"
                    />
                    <path
                        d="M208.9,161.8c4.1,0,7.3,3.3,7.2,7.4,0,4-3.3,7.2-7.3,7.2-4.1,0-7.3-3.3-7.3-7.3,0-4,3.3-7.2,7.4-7.2Z"
                        fill="#000"
                    />
                    <path
                        d="M207.9,138.9c0-4.1,3.2-7.4,7.2-7.4,4,0,7.3,3.3,7.3,7.3,0,4-3.2,7.2-7.2,7.3-4.1,0-7.3-3.1-7.3-7.2Z"
                        fill="#000"
                    />
                </g>
            </svg>
        </div>
    );
}

// eslint-disable-next-line @eslint-react/no-prop-types
Dices.propTypes = propTypes;

export default Dices;
