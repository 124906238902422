import { useIntersectionObserver } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../../styles/animations/jeu-aide-machine/game-machine.module.css';

const propTypes = {
    className: PropTypes.string,
};

function GameMachine({ className }) {
    const {
        ref: containerRef,
        entry: { isIntersecting = false },
    } = useIntersectionObserver({ rootMargin: '-20%' });

    return (
        <div
            className={classNames([
                styles.container,
                className,
                { [styles.visible]: isIntersecting },
            ])}
            ref={containerRef}
        >
            <svg
                id="Calque_1"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 220 372.1"
            >
                <path
                    d="M180.1,186c0,52.2-.2,104.5.1,156.7.1,15.7-12.7,29.4-29.3,29.2-40.6-.3-81.3-.4-121.9,0C13.1,372.2,0,357.6,0,342.8.1,238.2,0,133.6,0,29,0,14.4,10.7,2.2,25,.6,27.8.2,30.7,0,33.5,0,72.2,0,110.9,0,149.6,0c10.1,0,18.3,3.6,24.8,11.7,4.1,5.1,5.7,10.6,5.7,16.9,0,52.5,0,104.9,0,157.4ZM168.1,327.8V40.1H11.9c0,1.6-.2,3.1-.2,4.5,0,92.9,0,185.8,0,278.7,0,4.5,0,4.5,4.4,4.5,49.3,0,98.6,0,147.9,0s2.5,0,4,0ZM98.1,349.6c0-4.4-3.8-7.9-8.3-7.8-4.2.1-7.8,3.7-7.7,8,0,4.3,3.8,8.1,8.1,8,4.3,0,8-3.9,7.9-8.2ZM90.2,22.1c-3.2,0-6.4-.2-9.5,0-1.1,0-2.1,1.2-3.1,1.8,1,.7,1.9,1.9,2.9,1.9,6.4.2,12.7.2,19.1,0,1,0,2-1.3,2.9-1.9-1-.6-2-1.7-3.1-1.8-3.1-.2-6.1,0-9.2,0Z"
                    fill="#ff4325"
                />
                <rect x="11.9" y="40.1" width="156.2" height="287.7" fill="#fff" />
                <path
                    d="M98.1,349.6c0,4.3-3.6,8.1-7.9,8.2-4.3,0-8.1-3.7-8.1-8,0-4.2,3.5-7.8,7.7-8,4.4-.1,8.2,3.4,8.3,7.8Z"
                    fill="#fff"
                />
                <path
                    d="M90.2,22.1c3.1,0,6.2-.2,9.2,0,1.1,0,2.1,1.2,3.1,1.8-1,.7-1.9,1.9-2.9,1.9-6.4.2-12.7.2-19.1,0-1,0-2-1.3-2.9-1.9,1-.6,2-1.7,3.1-1.8,3.2-.2,6.4,0,9.5,0Z"
                    fill="#fff"
                />
                <path
                    d="M142.9,118.8c.7.5,1.7,1.1,2.5,1.9.7.7,1.2,1.7,1.7,2.6,0,.2-.8,1.1-.9,1-.8-.4-1.5-1-2.5-1.7-.6,1.9-.4,3,1.7,4,1.2.6,2.6,2.4,2.6,3.6,0,1.2-1.7,2.4-2.8,3.5-.7.7-1.6,1.1-2.4,1.7-1.5-1.6-3.2-3.4-5-5.2.4-.4.7-.9,1.1-1.3,1.1.8,2.2,1.5,3.3,2.3.2,0,.5-.2.7-.3-.7-1.3-1.1-2.9-2.1-3.7-2.6-2.2-2.6-4.9,0-6.8.8-.5,1.5-1.2,2.1-1.7Z"
                    fill="#fff"
                />
                <rect x="30.2" y="269.4" width="123.8" height="40.8" fill="#751304" />
                <g>
                    <rect x="31" y="64.8" width="122.5" height="67.4" fill="none" />
                    <path
                        d="M41.5,76c0-6.8,3.6-10.9,9.4-10.9s7.5,2.7,8.4,7.3l-3.3.7c-.6-3.2-2.5-5-5.1-5s-5.9,3-5.9,7.9c0,4.9,2.5,7.9,6.5,7.9s5.6-2.3,6.1-6l3.3.7c-.7,5.2-4.4,8.4-9.4,8.4s-10-4.2-10-10.9Z"
                        fill="#000"
                    />
                    <path
                        d="M67.4,65.7h4.3l7,20.5h-3.6l-1.5-4.6h-8.2l-1.5,4.6h-3.6l7-20.5ZM72.9,78.9l-3.2-10.1h0l-3.3,10.1h6.6Z"
                        fill="#000"
                    />
                    <path
                        d="M79.1,79.7l3.3-.6c0,2.9,2.3,4.9,5.2,4.9s4-1.4,4-3.2-1-2.4-3.4-3l-3-.9c-3.6-1-5.2-2.7-5.2-5.7s2.6-6.2,6.9-6.2,6.7,2.4,7,6l-3.2.7c0-2.3-1.7-3.9-3.8-3.9s-3.5,1.3-3.5,3,.9,2.4,3.1,3l3,.8c3.8,1,5.5,2.8,5.5,5.7s-2.8,6.4-7.5,6.4-8.2-2.9-8.4-7.2Z"
                        fill="#000"
                    />
                    <path d="M97.2,65.7h3.5v20.5h-3.5v-20.5Z" fill="#000" />
                    <path
                        d="M104,65.7h4.6l8,16.1h0v-16.1h3.4v20.5h-4.5l-8-16.1h0v16.1h-3.4v-20.5Z"
                        fill="#000"
                    />
                    <path
                        d="M122.7,76c0-6.6,4.1-10.9,10.2-10.9s10.2,4.3,10.2,10.9-4.1,10.9-10.2,10.9-10.2-4.4-10.2-10.9ZM139.6,76c0-4.8-2.6-7.9-6.7-7.9s-6.8,3.1-6.8,7.9,2.7,7.9,6.8,7.9,6.7-3.1,6.7-7.9Z"
                        fill="#000"
                    />
                    <path
                        d="M34.6,92.7h12.8v3h-9.4v5.7h8.2v2.7h-8.2v6.2h9.7v3h-13.1v-20.5Z"
                        fill="#000"
                    />
                    <path
                        d="M50.1,92.7h4.6l8,16.1h0v-16.1h3.4v20.5h-4.5l-8-16.1h0v16.1h-3.4v-20.5Z"
                        fill="#000"
                    />
                    <path d="M75.6,92.7h3.5v17.5h8.3v3h-11.8v-20.5Z" fill="#000" />
                    <path d="M89.5,92.7h3.5v20.5h-3.5v-20.5Z" fill="#000" />
                    <path
                        d="M95.6,103c0-6.6,3.9-10.9,9.9-10.9s7.2,2.4,8.4,6.1l-3.3.9c-.8-2.3-2.8-3.9-5.2-3.9-3.8,0-6.3,3.1-6.3,7.9s2.7,7.9,6.8,7.9,6.4-2.6,6.4-6.4h-8.9v-2.7h12.4v11.5h-2.7l-.3-3.4h0c-1.5,2.5-4.3,4-7.4,4-5.8,0-9.7-4.3-9.7-10.9Z"
                        fill="#000"
                    />
                    <path
                        d="M118.5,92.7h4.6l8,16.1h0v-16.1h3.4v20.5h-4.5l-8-16.1h0v16.1h-3.4v-20.5Z"
                        fill="#000"
                    />
                    <path
                        d="M137.8,92.7h12.8v3h-9.4v5.7h8.2v2.7h-8.2v6.2h9.7v3h-13.1v-20.5Z"
                        fill="#000"
                    />
                </g>
                <g>
                    <path d="M71.4,203.3h-3.8v-2h10v2h-3.8v11.5h-2.3v-11.5Z" fill="#ff4325" />
                    <path
                        d="M78.6,210.1v-8.8h2.3v8.7c0,1.8,1.1,3.2,3,3.2s2.9-1.3,2.9-3.2v-8.7h2.3v8.8c0,3.1-2.1,5.1-5.2,5.1s-5.3-2-5.3-5.1Z"
                        fill="#ff4325"
                    />
                    <path
                        d="M98.8,201.3h2.8l4.6,13.5h-2.4l-1-3h-5.3l-1,3h-2.3l4.6-13.5ZM102.4,210l-2.1-6.6h0l-2.1,6.6h4.3Z"
                        fill="#ff4325"
                    />
                    <path
                        d="M106.5,210.5l2.2-.4c0,1.9,1.5,3.2,3.4,3.2s2.6-.9,2.6-2.1-.6-1.6-2.2-2l-2-.6c-2.4-.6-3.4-1.8-3.4-3.7s1.7-4,4.5-4,4.4,1.6,4.6,3.9l-2.1.5c0-1.5-1.1-2.5-2.5-2.5s-2.3.8-2.3,1.9.6,1.6,2,2l2,.6c2.5.7,3.6,1.8,3.6,3.8s-1.8,4.2-4.9,4.2-5.3-1.9-5.5-4.7Z"
                        fill="#ff4325"
                    />
                    <path
                        d="M63.6,218.3h5.3c2.8,0,4.7,1.7,4.7,4.2s-1.9,4.2-4.7,4.2h-3.1v5.1h-2.2v-13.5ZM68.7,224.8c1.5,0,2.7-.9,2.7-2.3s-1.1-2.3-2.7-2.3h-2.8v4.6h2.8Z"
                        fill="#ff4325"
                    />
                    <path
                        d="M75.1,218.3h8.4v1.9h-6.2v3.7h5.4v1.8h-5.4v4.1h6.3v1.9h-8.6v-13.5Z"
                        fill="#ff4325"
                    />
                    <path
                        d="M92.1,230.5l-.8-2.7c-.3-.9-1-1.5-1.7-1.5h-2.1v5.5h-2.3v-13.5h5.6c2.8,0,4.7,1.6,4.7,4s-1.2,3.2-3.2,3.6h0c.7.4,1.1.9,1.2,1.6l.6,2.2c.1.4.3.7.7.7s.6-.2.9-.7l.8,1.2c-.6.7-1.4,1.2-2.4,1.2s-1.9-.7-2.1-1.7ZM90.7,224.5c1.5,0,2.6-.9,2.6-2.1s-1.1-2.2-2.6-2.2h-3.2v4.3h3.2Z"
                        fill="#ff4325"
                    />
                    <path
                        d="M97.7,218.3h4.8c4.3,0,6.7,2.4,6.7,6.7s-2.4,6.7-6.7,6.7h-4.8v-13.5ZM102.4,229.9c2.8,0,4.4-1.7,4.4-4.8s-1.6-4.8-4.4-4.8h-2.5v9.6h2.5Z"
                        fill="#ff4325"
                    />
                    <path
                        d="M110.6,227.1v-8.8h2.3v8.7c0,1.8,1.1,3.2,3,3.2s2.9-1.3,2.9-3.2v-8.7h2.3v8.8c0,3.1-2.1,5.1-5.2,5.1s-5.3-2-5.3-5.1Z"
                        fill="#ff4325"
                    />
                </g>
                <g>
                    <rect x="31" y="280.9" width="122.5" height="29.3" fill="none" />
                    <path
                        d="M59.5,293.6l-.8-2.7c-.3-.9-1-1.5-1.7-1.5h-2.1v5.5h-2.3v-13.5h5.6c2.8,0,4.7,1.6,4.7,4s-1.2,3.2-3.2,3.6h0c.7.4,1.1.9,1.2,1.6l.6,2.2c.1.4.3.7.7.7s.6-.2.9-.7l.8,1.2c-.6.7-1.4,1.2-2.4,1.2s-1.9-.7-2.1-1.7ZM58.1,287.6c1.5,0,2.6-.9,2.6-2.1s-1.1-2.2-2.6-2.2h-3.2v4.3h3.2Z"
                        fill="#fff"
                    />
                    <path
                        d="M65.1,281.5h8.4v1.9h-6.2v3.7h5.4v1.8h-5.4v4.1h6.3v1.9h-8.6v-13.5Z"
                        fill="#fff"
                    />
                    <path
                        d="M74.5,291.7l2-.8c.1,1.6.9,2.5,2.1,2.5s1.7-.8,1.7-2.1v-9.8h2.3v10c0,2.5-1.4,3.8-3.9,3.8s-3.9-1.3-4.1-3.6Z"
                        fill="#fff"
                    />
                    <path
                        d="M84.1,288.2c0-4.3,2.7-7.1,6.7-7.1s6.7,2.8,6.7,7.1-2.7,7.2-6.7,7.2-6.7-2.8-6.7-7.2ZM95.2,288.2c0-3.1-1.7-5.2-4.4-5.2s-4.4,2.1-4.4,5.2,1.7,5.2,4.4,5.2,4.4-2.1,4.4-5.2Z"
                        fill="#fff"
                    />
                    <path
                        d="M99.1,290.2v-8.8h2.3v8.7c0,1.8,1.1,3.2,3,3.2s2.9-1.3,2.9-3.2v-8.7h2.3v8.8c0,3.1-2.1,5.1-5.2,5.1s-5.3-2-5.3-5.1Z"
                        fill="#fff"
                    />
                    <path
                        d="M111.5,281.5h8.4v1.9h-6.2v3.7h5.4v1.8h-5.4v4.1h6.3v1.9h-8.6v-13.5Z"
                        fill="#fff"
                    />
                    <path
                        d="M128.5,293.6l-.8-2.7c-.3-.9-.9-1.5-1.7-1.5h-2.1v5.5h-2.3v-13.5h5.6c2.8,0,4.7,1.6,4.7,4s-1.2,3.2-3.2,3.6h0c.7.4,1.1.9,1.2,1.6l.6,2.2c.1.4.3.7.7.7s.6-.2.9-.7l.8,1.2c-.6.7-1.4,1.2-2.4,1.2s-1.9-.7-2.1-1.7ZM127.1,287.6c1.5,0,2.6-.9,2.6-2.1s-1.1-2.2-2.6-2.2h-3.2v4.3h3.2Z"
                        fill="#fff"
                    />
                </g>
                <rect x="199.6" y="104.8" width="10.6" height="71.5" fill="#ffda00" />
                <path
                    d="M190.6,194.1c0-4.8,0-9.6,0-14.3,0-2.4,1.1-3.5,3.5-3.5,6.9,0,13.9,0,20.8,0,3.1,0,4.5,1.3,4.5,4.6-.1,8.4,0,16.8,0,25.3,0,4.2-1,5.2-5.3,5.2-6.6,0-13.2,0-19.8,0-2.7,0-3.8-1-3.7-3.8.2-4.4,0-8.9,0-13.3Z"
                    fill="#ff4325"
                />
                <path
                    d="M220,92.2c0,7.6-6.3,14.2-13.9,14.3-8.3,0-15.3-6.6-15.3-14.6,0-8,6.5-14.7,14.3-14.7,8.9,0,14.8,5.8,14.9,15Z"
                    fill="#ff4325"
                />
                <rect
                    x="179.5"
                    y="164.6"
                    width="11.5"
                    height="58"
                    rx="2.5"
                    ry="2.5"
                    fill="#ffda00"
                />
                <rect x="30.4" y="132.3" width="37.1" height="40.8" fill="#751304" />
                <rect x="73.9" y="132.3" width="37.1" height="40.8" fill="#751304" />
                <rect x="117.1" y="132.3" width="37.1" height="40.8" fill="#751304" />
                <g>
                    <g className={styles.lose}>
                        <path
                            d="M38.7,151.4c0-4.3,2.1-7.1,5.4-7.1s5.4,2.8,5.4,7.1-2.1,7.1-5.4,7.1-5.4-2.8-5.4-7.1ZM47.2,151.4c0-3.2-1.2-5.2-3.1-5.2s-3.1,2-3.1,5.2,1.2,5.2,3.1,5.2,3.1-2,3.1-5.2Z"
                            fill="#fff"
                        />
                        <path
                            d="M56.3,158.6v2.1h-.8v-2.1c-2.8-.2-4.7-2.1-4.8-4.7l2.2-.4c0,1.6,1.1,2.8,2.6,3.1v-4.4l-.8-.2c-2.4-.6-3.4-1.8-3.4-3.7s1.6-3.9,4.2-4v-1.7h.8v1.7c2.4.2,4,1.7,4.2,3.9l-2.1.5c0-1.3-.9-2.3-2-2.5v4l1.3.4c2.5.7,3.6,1.8,3.6,3.8s-1.8,4.2-4.9,4.2ZM55.5,150.1v-3.9c-1.2.1-2,.9-2,1.9s.6,1.5,2,1.9ZM58.9,154.6c0-1-.6-1.6-2.2-2h-.4c0-.1,0,4.1,0,4.1h0c1.6,0,2.6-.9,2.6-2.1Z"
                            fill="#fff"
                        />
                    </g>
                    <g className={styles.lose}>
                        <path
                            d="M83,151.2c0-4.3,2.1-7.1,5.4-7.1s5.4,2.8,5.4,7.1-2.1,7.1-5.4,7.1-5.4-2.8-5.4-7.1ZM91.4,151.2c0-3.2-1.2-5.2-3.1-5.2s-3.1,2-3.1,5.2,1.2,5.2,3.1,5.2,3.1-2,3.1-5.2Z"
                            fill="#fff"
                        />
                        <path
                            d="M100.5,158.3v2.1h-.8v-2.1c-2.8-.2-4.7-2.1-4.8-4.7l2.2-.4c0,1.6,1.1,2.8,2.6,3.1v-4.4l-.8-.2c-2.4-.6-3.4-1.8-3.4-3.7s1.6-3.9,4.2-4v-1.7h.8v1.7c2.4.2,4,1.7,4.2,3.9l-2.1.5c0-1.3-.9-2.3-2-2.5v4l1.3.4c2.5.7,3.6,1.8,3.6,3.8s-1.8,4.2-4.9,4.2ZM99.8,149.8v-3.9c-1.2.1-2,.9-2,1.9s.6,1.5,2,1.9ZM103.2,154.3c0-1-.6-1.6-2.2-2h-.4c0-.1,0,4.1,0,4.1h0c1.6,0,2.6-.9,2.6-2.1Z"
                            fill="#fff"
                        />
                    </g>
                    <g className={styles.lose}>
                        <path
                            d="M125.3,151.4c0-4.3,2.1-7.1,5.4-7.1s5.4,2.8,5.4,7.1-2.1,7.1-5.4,7.1-5.4-2.8-5.4-7.1ZM133.8,151.4c0-3.2-1.2-5.2-3.1-5.2s-3.1,2-3.1,5.2,1.2,5.2,3.1,5.2,3.1-2,3.1-5.2Z"
                            fill="#fff"
                        />
                        <path
                            d="M142.9,158.6v2.1h-.8v-2.1c-2.8-.2-4.7-2.1-4.8-4.7l2.2-.4c0,1.6,1.1,2.8,2.6,3.1v-4.4l-.8-.2c-2.4-.6-3.4-1.8-3.4-3.7s1.6-3.9,4.2-4v-1.7h.8v1.7c2.4.2,4,1.7,4.2,3.9l-2.1.5c0-1.3-.9-2.3-2-2.5v4l1.3.4c2.5.7,3.6,1.8,3.6,3.8s-1.8,4.2-4.9,4.2ZM142.1,150.1v-3.9c-1.2.1-2,.9-2,1.9s.6,1.5,2,1.9ZM145.5,154.6c0-1-.6-1.6-2.2-2h-.4c0-.1,0,4.1,0,4.1h0c1.6,0,2.6-.9,2.6-2.1Z"
                            fill="#fff"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
}

// eslint-disable-next-line @eslint-react/no-prop-types
GameMachine.propTypes = propTypes;

export default GameMachine;
