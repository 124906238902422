/* eslint-disable react/jsx-props-no-spreading */
import { getComponentFromName } from '@folklore/utils';
import PropTypes from 'prop-types';

import * as Components from './components';

const propTypes = {
    kind: PropTypes.string,
};

function JeuAideMAchineAnimation({ kind = null, ...otherProps }) {
    const Component = getComponentFromName(Components, kind, null);
    return Component !== null ? <Component {...otherProps} /> : null;
}

// eslint-disable-next-line @eslint-react/no-prop-types
JeuAideMAchineAnimation.propTypes = propTypes;

export default JeuAideMAchineAnimation;
