import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../../styles/animations/jeu-aide-machine/game-wheel.module.css';

const propTypes = {
    className: PropTypes.string,
};

function GameWheel({ className }) {
    return (
        <div className={classNames([styles.container, className])}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 576 576">
                <g id="Calque_3">
                    <path
                        d="M227,469.2c-4.7,14-9.4,28-14,42.1-.8,2.4-1.4,4.8-2.2,7.5-.4,0-1.3.3-2.2.3-5.7,0-11.5,0-17.2,0-7.9,0-16.3,5.2-19.5,12.5-6.7,15.2,3.7,31.3,20.3,31.4,67.3,0,134.7,0,202,0,4.9,0,9.7-1.1,13.7-4,7.1-5.1,9.9-12.3,9-20.8-1.2-11.1-9.5-18.5-20.7-19.1-5.5-.3-11.1-.2-16.6,0-2.4,0-3.3-.7-4.1-3-5-15.5-10.2-31-15.5-46.5M144.9,409"
                        fill="#9b1e18"
                    />
                </g>
                <g id="Calque_2" className={styles.wheel}>
                    <circle cx="288.4" cy="278.5" r="210.8" fill="#e84731" />
                    <ellipse
                        cx="289.2"
                        cy="279.4"
                        rx="180.9"
                        ry="180.8"
                        fill="none"
                        stroke="#a52218"
                        strokeMiterlimit="10"
                        strokeWidth="7"
                    />
                    <ellipse cx="289.2" cy="279.4" rx="180.9" ry="180.8" fill="#fef8db" />
                    <g id="Calque_1">
                        <path
                            d="M488,271.4c4.5,0,7.8,3.3,7.7,7.8,0,4.3-3.5,7.6-7.8,7.6-4.3,0-7.9-3.5-7.8-7.8,0-4.3,3.5-7.9,7.9-7.6Z"
                            fill="#fef8db"
                        />
                        <path
                            d="M480.5,355.4c.4,3.7-3.3,7.7-7.9,7.8-4.1,0-7.9-3.8-7.8-7.9.1-4.4,3.1-7.7,7.8-7.9,4.1-.2,8.2,3.3,7.9,8Z"
                            fill="#fef8db"
                        />
                        <path
                            d="M472.4,195.1c4.5-.2,8.5,3.5,8.1,7.9.3,3.4-2.7,7.9-7.8,7.7-4.7-.1-7.6-2.9-7.8-7.8-.2-4.7,3.8-7.7,7.5-7.9Z"
                            fill="#fef8db"
                        />
                        <path
                            d="M467.4,278.9c2.8,0,2.8,0,2.7,2.7-.6,19.7-3.7,38.9-10.3,57.5-1.1,3.1-2.3,6.1-3.5,9.3-55.6-23.1-111-46-166.3-68.9,0-.2,0-.3.1-.5.6,0,1.3,0,1.9,0,58.4,0,116.8,0,175.2,0ZM453.9,315.3c2-2.7,2.5-5.8.8-8.8-.2-.3-.3-.6-.5-.9s-.4-.5-.6-.8c-.1-.1-.2-.2-.3-.3-1.3-1.2-2.9-1.9-4.9-1.9-.5,1.3-.9,2.4-1.3,3.6,1.1.5,2.3.7,3.1,1.4.8.7,1.6,1.9,1.6,2.8,0,.9-1,2-1.8,2.5-1.9,1.1-4.3.4-5.8-1.5-2-2.7-4.5-4.7-8.2-4.7-.2,1.2-.3,2.4-.4,2.9,2,1.6,4.1,2.7,5.4,4.4,1.6,2,3.4,3.4,5.8,3.8,2.8.5,5.4-.3,7.2-2.6ZM433.1,308c.1-.9-.1-1.5-.6-1.9,0,0-.2-.2-.3-.3-.4-.3-1-.5-1.6-.6-1.2-.2-2.7,1.4-2.8,2.8,0,.7.3,1.4.9,1.9.2.2.3.3.5.5.4.3.9.5,1.3.5,1.4,0,2.6-1.1,2.6-2.8Z"
                            fill="#e94936"
                        />
                        <path
                            d="M455.6,208c.6,1.2.4,1.9-1,2.5-24.3,10-48.5,20-72.8,30-22.2,9.2-44.4,18.5-66.6,27.7-8.3,3.5-16.6,6.9-24.9,10.3-.1,0-.3,0-.4,0,42.4-42.4,84.8-84.8,127.8-127.8.3.6.5,1.4,1,1.9,8.1,8.8,15.7,17.9,22.2,28,5.6,8.8,10.4,17.9,14.7,27.4ZM431.5,193.2c1-1.6,1.3-3.6.8-5.6-1.1-4.1-4.2-6.5-9-6-.7,0-1.3.2-1.7.4-.1,0-.3.1-.4.2-.7.5-.8,1.4-.4,2.7,0,.2.1.4.2.6,0,.2.2.4.3.6,0,.2.2.4.3.6,1.1-.4,2.1-1.1,3.1-1,1,0,2.2.4,2.8,1.1,0,0,.2.2.3.3,0,0,0,0,0,.2,0,.1.2.3.2.5,0,0,0,.1,0,.2.1.6,0,1.3-.1,1.9,0,.1,0,.2-.1.3-.1.3-.3.6-.5.8-1.2,1.4-3.2,1.8-5.1,1.4-1.8-.3-3.7-.6-5.4-.2-1.6.4-2.6.7-3.1,1.1,0,0,0,0-.1.1-.4.4-.2.9.3,1.7.2.3.3.5.6.9.2.3.5.7.8,1.1h0c3.2-1.5,3.2-1.4,6.9-.6,1.3.3,2.7.4,4,.1,1.7-.3,3.1-1.1,4.2-2.1.2-.1.3-.3.5-.5.3-.3.5-.7.8-1ZM409.9,201.3c1.4,0,2.5-1.4,2.5-2.9,0-1.5-1.4-3.2-2.6-2.7-1.1.5-2.2,1.5-2.6,2.6-.5,1.3,1.3,3,2.7,3Z"
                            fill="#e84731"
                        />
                        <path d="M454.2,305.6c.2.3.4.6.5.9-.2-.3-.3-.6-.5-.9Z" />
                        <path d="M453.6,304.8c-.1-.1-.2-.2-.3-.3.1.1.2.2.3.3Z" />
                        <path
                            d="M429.7,130.6c4.9,0,7.9,4.1,7.7,8.2-.2,4-3.9,7.8-8,7.5-4-.3-7.7-3-7.5-8.1,0-5,3.9-7.7,7.7-7.6Z"
                            fill="#fef8db"
                        />
                        <path
                            d="M429.6,412.1c4-.2,7.8,3.1,7.8,7.7,0,4.8-3.8,7.7-7.8,7.8-3.7,0-7.9-3.4-7.7-7.7.2-4.6,3.2-7.8,7.7-7.8Z"
                            fill="#fef8db"
                        />
                        <path d="M432.2,305.8c.1,0,.2.2.3.3,0,0-.2-.2-.3-.3Z" />
                        <path d="M431.5,193.2c-.2.4-.5.7-.8,1,.3-.3.5-.7.8-1Z" />
                        <path d="M430.3,194.6c.2-.1.3-.3.5-.5-.1.2-.3.3-.5.5Z" />
                        <path d="M421.2,182.2c.1,0,.2-.1.4-.2-.1,0-.3.1-.4.2Z" />
                        <path
                            d="M416.7,407.7c-3.4,3-6.8,6.5-10.6,9.4-6.4,5-12.8,10-19.5,14.5-8.3,5.6-17.3,10.1-26.5,14.2-1.4.6-1.9.4-2.5-1.1-6.6-16.1-13.3-32.2-19.9-48.2-7.1-17-14.1-34-21.2-51-8.7-21-17.4-42.1-26.1-63.1-.2-.6-.4-1.2-.6-1.8,42.2,42.3,84.5,84.5,127.1,127.1ZM386.3,416.2c.9-1.8,1-3.5.5-5.1-.1-.4-.3-.8-.5-1.2-.1-.2-.2-.4-.3-.6-.2-.4-.5-.8-.7-1.2h0c-1.3.6-2.5,1.1-3.5,1.5.2.8.5,1.5.7,2.3,0,.1,0,.3.1.4,0,.3,0,.6,0,.8s0,.1,0,.2c0,.1,0,.2,0,.4-.2.9-.7,1.9-1.3,2.5-1.1,1-2.4.4-3.5-.4-2.2-1.6-2.2-4-1.6-6.2.8-3.1-.5-5.4-2-7.8-.6.3-1,.5-1.4.7-1.5.8-2,1.6-1,3.4,0,.1.1.2.2.4.3,1,0,2.4,0,3.5,0,.6,0,1.1,0,1.7-.1,1.4-.2,2.7.1,3.9,1,3.1,3.3,5.2,6.7,5.5,2.1.2,4.3-.7,5.9-2.4.3-.3.5-.5.8-.9.4-.4.7-.9.9-1.4ZM372.2,398.7c0-1.2-1.4-2.6-2.8-2.7-1.3,0-3,1.4-2.8,2.6.3,1.4,1,2.6,2.6,2.6,1.4,0,2.9-1.3,3-2.6Z"
                            fill="#e84731"
                        />
                        <path d="M386.4,409.8c.2.4.3.8.5,1.2-.1-.4-.3-.8-.5-1.2Z" />
                        <path d="M385.3,408c.3.4.5.8.7,1.2-.2-.4-.5-.8-.8-1.2h0Z" />
                        <path d="M385.4,417.6c-.2.3-.5.6-.8.9.3-.3.5-.5.8-.9Z" />
                        <path d="M382.4,411.8c0,.1,0,.3.1.4,0-.2,0-.3-.1-.4Z" />
                        <path
                            d="M365.1,455.2c4.3-.3,8,3.4,7.9,7.9-.1,4.6-3.9,7.7-7.9,7.7-3.7,0-8.1-3.1-7.8-8.4.2-3.9,3.6-7.5,7.9-7.2Z"
                            fill="#fef8db"
                        />
                        <path
                            d="M365,87.3c4.1-.2,8,3.7,8,8,0,4.3-3.6,7.9-7.8,7.9-3.9,0-8.1-3.2-7.9-7.9-.3-4.4,3.6-7.7,7.7-8Z"
                            fill="#fef8db"
                        />
                        <path
                            d="M289,470.2c4.7.4,7.6,3.7,7.8,8-.3,4.7-3.7,7.6-8,7.8-4.1.1-7.9-4-7.8-8,.1-4.2,4-8.1,8-7.8Z"
                            fill="#fef8db"
                        />
                        <path
                            d="M288.8,280.3v179.9c-13.8,2-55.6-6.4-69.1-13.8,22.9-55.4,45.9-110.7,68.8-166.1.1,0,.2,0,.3,0ZM269.8,442.1c2.1-2.9,1.6-4.1-1.8-5-.2,0-.5-.1-.7-.2-.2.6-.4,1.2-.6,1.7,0,.1,0,.3-.1.4-.2.4-.4.8-.6,1-.8.8-2.3,1.6-3.3,1.4-1-.2-2.1-1.6-2.3-2.7-.4-1.8.3-3.5,1.8-4.9,1.4-1.3,3-2.8,3.8-4.5,1.7-3.8,1.3-4.2-3-3.8h0c0,.3,0,.6-.2.9-.5,2.1-1.8,3.6-3.7,4.8-3,1.9-3.9,6-2.9,9.3.9,2.7,2.8,4.2,5.6,4.7,3.4.6,6.1-.4,8.1-3.2ZM268.1,420.5c0-1.5-1.2-2.6-2.8-2.6-1.6,0-2.9,1-2.9,2.5,0,.4,0,.7.2,1,0,.2.1.3.2.4.5.7,1.3,1.1,2.3,1.1,1,0,1.9-.4,2.4-1.1.1-.1.2-.3.2-.4.1-.2.2-.5.2-.7,0,0,0-.2,0-.3Z"
                            fill="#e84731"
                        />
                        <path d="M288.3,278.9h0s0,0,0,0h0s0,0,0,0Z" fill="#fff" />
                        <path
                            d="M288.3,278.9h0c-3.4-3.5-6.9-7.1-10.3-10.6-34-34.1-68.1-68.1-102.1-102.2s-9-9-13.5-13.5-.9-1-1.4-1.5c8.1-11.1,41.4-33.5,58.6-39.3,2.2,5.2,4.4,10.5,6.6,15.8,6.1,14.6,12.1,29.2,18.2,43.9,13.1,31.5,26.1,63,39.2,94.5,1.4,3.5,2.9,6.9,4.2,10.4.3.8.4,1.6.6,2.5ZM209.4,161.6c1.7,0,2.6-.9,2.6-2.7,0-1.2-1.5-2.7-2.6-2.7-.6,0-1.2.3-1.7.7,0,0-.1.1-.2.2,0,0-.1.1-.2.2-.2.3-.4.6-.5,1,0,0,0,.2,0,.3,0,.2,0,.4,0,.6,0,.3,0,.6.1.8.3,1.1,1.2,1.6,2.5,1.6ZM209,146.5c1.5-3.4.4-7-2.7-9.5-2.1-1.8-5.6-2-8.3-.4-1.3.8-2.3,1.7-3,2.8-.2.3-.3.5-.4.8,0,.1-.1.3-.2.4,0,0,0,.2,0,.2-.3,1-.4,2-.2,3.1,0,.3,0,.6.1,1,0,.1,0,.3,0,.4s0,.2,0,.4c0,.2.1.5.2.7,0,0,.1.2.2.2.3.4.8.5,1.5.2.3-.1.8-.1,1.2-.2.1,0,.3,0,.4,0,.6-.1,1-.4.9-1.3,0-.3-.1-.6-.3-1-.3-.8-.3-1.6,0-2.4,0-.2.1-.3.2-.5.1-.3.3-.5.4-.7l.2-.2c0,0,.1-.1.2-.2,0,0,0,0,.1-.1.3-.3.6-.5.9-.6,1.4-.5,3.4.4,4,2.4.5,1.4.5,3.3,0,4.7-1.1,3.1-.9,5.8.7,8.7,1.2-.6,2.3-1.2,3.6-1.8-.2-.3-.3-.5-.4-.8-.1-.3-.2-.5-.3-.8s-.1-.5-.2-.8c-.2-1.6.3-3.1,1-4.7Z"
                            fill="#e84731"
                        />
                        <path d="M288.3,278.9s0,0,0,0h0Z" fill="#fff" />
                        <path
                            d="M288.3,278.9c-.9,0-1.8.1-2.7.1H108.2c-2.4-13.3,6.3-57.4,13.6-69.1,4,1.6,8,3.3,12,5,29.2,12.1,58.4,24.2,87.7,36.3,22.3,9.2,44.6,18.5,66.8,27.7ZM145.7,254.6c1.6,0,2.6-1,2.6-2.8s-1.2-2.8-2.7-2.7c-1.3,0-2.8,1.6-2.6,3,.2,1.5,1,2.5,2.7,2.5ZM141.2,249.3v-.2c-3.4-.3-4.8-2.9-6.3-5.3,0-.1-.2-.3-.3-.4-.1-.1-.2-.3-.3-.4-.1-.1-.2-.2-.3-.3-2.4-2.3-6.7-2.4-8.8-1.3-2.9,1.4-4.7,5-4.2,8.2.5,3.3,3,5.7,6.8,6.5h0c.1-.3.2-.6.4-.9.4-.9.7-1.8,1.1-2.6-1.1-.7-2.3-1.1-3-1.8-.1-.1-.2-.2-.3-.3-.2-.2-.3-.5-.4-.8-.3-.8-.4-1.7-.2-2.4.2-.7,1.3-1.6,2.2-1.8,2.2-.4,4.1.2,5.4,2.3.9,1.4,2,2.7,3.2,3.7,2.9,2.2,4.2,1.8,5-1.5,0,0,0-.1,0-.2,0,0,0-.1,0-.2,0,0,0-.2.1-.3Z"
                            fill="#e84731"
                        />
                        <path d="M268,420.7c0,.3-.1.5-.2.7.1-.2.2-.5.2-.7Z" />
                        <path d="M267.8,421.5c0,.1-.1.3-.2.4,0-.1.2-.3.2-.4Z" />
                        <path
                            d="M212.7,455.2c4.8,0,7.9,3.2,7.8,8.1,0,4.4-3.3,7.4-7.8,7.4-3.7.5-8.1-3.4-7.8-8.2.3-4.2,3.4-7.3,7.8-7.3Z"
                            fill="#fef8db"
                        />
                        <path
                            d="M220.5,95.3c.3,4.8-3.8,7.9-7.8,7.9-4.1,0-7.7-3.3-7.7-7.8,0-5.1,4.2-8.1,7.9-8,4,.1,7.8,3,7.6,7.9Z"
                            fill="#fef8db"
                        />
                        <path
                            d="M297.9,79.8c.3,4.8-3.8,7.9-7.8,7.9-4.1,0-7.7-3.3-7.7-7.8,0-5.1,4.2-8.1,7.9-8,4,.1,7.8,3,7.6,7.9Z"
                            fill="#fef8db"
                        />
                        <path d="M208.5,152.8c.1.3.2.5.4.8-.2-.3-.3-.5-.4-.8Z" />
                        <path d="M208,151.2c0,.2,0,.5.2.8,0-.3-.1-.5-.2-.8Z" />
                        <g>
                            <path
                                d="M358.3,112.2c-2.2,5.2-4.3,10.5-6.5,15.8-6.1,14.6-12.1,29.2-18.2,43.9-13.1,31.5-26.1,63-39.1,94.5-1.4,3.5-2.9,6.9-4.3,10.3-.3.8-.9,1.4-1.4,2.2h0c0-4.9.2-9.9.2-14.8,0-48.1,0-96.3,0-144.4s0-12.7,0-19.1,0-1.3,0-2c13.6-2.2,52.9,5.6,69.3,13.6ZM326.3,129.2c3.4-1.3,5.2-4.7,4.9-8.6-.2-2.8-2.6-5.4-5.6-6.1-1.5-.4-2.9-.4-4.1-.1-.3,0-.6.2-.9.3-.1,0-.3.1-.4.2s-.2,0-.2.1c-.9.5-1.7,1.2-2.4,2.1-.2.2-.4.5-.6.8,0,.1-.1.2-.2.3,0,.1-.1.2-.2.3-.1.2-.2.4-.3.6,0,.1,0,.2,0,.3,0,.5.2.9.9,1.2.3.1.6.4,1,.7,0,0,.2.2.3.2.5.3,1,.4,1.5-.3.2-.2.3-.5.5-1,.3-.8.9-1.4,1.6-1.7.2,0,.3-.2.5-.2.3-.1.5-.2.8-.2.1,0,.2,0,.3,0s.2,0,.3,0c0,0,0,0,.1,0,.4,0,.8,0,1.1.2,1.3.6,2.1,2.7,1.2,4.5-.7,1.3-2,2.7-3.3,3.3-2.9,1.4-4.8,3.5-5.6,6.7,1.3.4,2.5.8,3.8,1.2,0-.3.2-.6.3-.9.1-.3.2-.5.4-.8.1-.2.3-.5.4-.7,1-1.3,2.4-2,4-2.7ZM319.7,140.2c.8-.9.9-2.9,0-3.7-.5-.4-1.1-.7-1.7-.7,0,0-.2,0-.3,0s-.2,0-.3,0c-.4,0-.7.2-1,.3,0,0-.2,0-.2.1-.2.1-.4.2-.5.4-.2.2-.4.5-.5.7-.6,1-.3,2,.6,2.9,1.2,1.2,2.5,1.2,3.8,0Z"
                                fill="#e84731"
                            />
                            <path
                                d="M331.2,120.6c.3,4-1.4,7.3-4.9,8.6-1.6.6-3.1,1.4-4,2.7-.2.2-.3.4-.4.7-.1.2-.3.5-.4.8-.1.3-.2.6-.3.9-1.3-.4-2.5-.8-3.8-1.2.9-3.2,2.7-5.3,5.6-6.7,1.4-.7,2.6-2,3.3-3.3.9-1.8,0-3.9-1.2-4.5-.3-.2-.7-.2-1.1-.2,0,0,0,0-.1,0,0,0-.2,0-.3,0s-.2,0-.3,0c-.3,0-.5,0-.8.2-.2,0-.3.1-.5.2-.7.4-1.3.9-1.6,1.7-.2.4-.3.7-.5,1-.5.7-1,.6-1.5.3-.1,0-.2-.1-.3-.2-.3-.3-.7-.5-1-.7-.7-.4-.9-.8-.9-1.2,0,0,0-.2,0-.3,0-.2.2-.4.3-.6,0-.1.1-.2.2-.3,0-.1.2-.2.2-.3.2-.3.4-.6.6-.8.7-.9,1.5-1.6,2.4-2.1,0,0,.1,0,.2-.1.1,0,.3-.1.4-.2.3-.1.6-.2.9-.3,1.2-.3,2.6-.3,4.1.1,3,.8,5.4,3.3,5.6,6.1Z"
                                fill="#fef8db"
                            />
                            <path
                                d="M319.8,136.5c.8.8.8,2.8,0,3.7-1.2,1.2-2.5,1.2-3.8,0-.9-.9-1.2-1.9-.6-2.9.1-.2.3-.5.5-.7.1-.2.3-.3.5-.4,0,0,.1-.1.2-.1.3-.2.7-.3,1-.3s.2,0,.3,0,.2,0,.3,0c.6,0,1.2.3,1.7.7Z"
                                fill="#fef8db"
                            />
                        </g>
                        <path d="M322.3,131.9c-.2.2-.3.4-.4.7.1-.2.3-.5.4-.7Z" />
                        <path d="M199.3,140.6l-.2.2c0,0,.1-.1.2-.2Z" />
                        <path d="M198.5,144.3c.2.4.3.8.3,1,0-.3-.1-.6-.3-1-.3-.8-.3-1.6,0-2.4-.2.8-.3,1.6,0,2.4Z" />
                        <path d="M194.7,140.2c.1-.3.3-.6.4-.8-.2.3-.3.5-.4.8Z" />
                        <path
                            d="M288.1,279.5c-42.7,42.7-85.1,85-127.6,127.5-16.6-16.8-29.7-36.2-39-58.5,55.5-23,110.8-45.9,166.6-69ZM167.2,363.9c.9-.2,1.9-.9,1.9-2.6,0-1.7-.9-2.7-2.6-2.7-1.6,0-2.5,1.1-2.7,2.5,0,.5,0,.9.3,1.3,0,.1.2.2.2.3.6.7,1.6,1.1,2.9,1.1ZM162.8,365.4c-.2-.4-.4-.9-.6-1.3-.1-.3-.3-.6-.4-.9l-.5-.9c0-.1-.2-.3-.2-.5h0c-2.5,1.5-4.7,1-7,0-3.2-1.4-6.6-.6-9.1,2-.1.1-.2.2-.3.4-.4.5-.8,1-1,1.6-.2.4-.3.8-.4,1.2-.5,1.8-.2,3.9.8,5.7,0,.1.2.3.2.4.2.3.4.5.6.8,1.8,2.1,4.8,3.6,7.9,2.6,1.2-.4,2.3-.6,1.8-2.1-.4-1.1.1-3.4-2.6-2.2-1.9.8-4.1-.5-4.7-2-.5-1.5.7-3.6,2.8-4.1,1.5-.3,3.2-.2,4.7.3,2.9.9,5.6.7,8.2-.9Z"
                            fill="#e84731"
                        />
                        <path d="M164,362.5c0,.1.2.2.2.3,0,0-.2-.2-.2-.3Z" />
                        <path d="M161.7,363.3c.2.3.3.6.4.9-.1-.3-.3-.6-.4-.9Z" />
                        <path d="M161.2,362.3l.5.9-.5-.9Z" />
                        <path d="M161,361.9c0,.2.2.3.2.5,0-.1-.2-.3-.2-.5h0Z" />
                        <path
                            d="M156.2,138.4c0,4-3.8,7.9-7.9,7.9-4.6-.4-7.9-3.3-7.9-7.8,0-4.8,3.1-7.5,7.8-7.9,4-.3,7.9,3.8,8,7.8Z"
                            fill="#fef8db"
                        />
                        <path
                            d="M148.3,412.1c4.2-.2,7.9,3.5,7.8,7.8-.1,4.6-3.9,7.8-8,7.6-4.7-.1-7.8-3.8-7.7-7.9,0-4.2,3-7.7,7.9-7.6Z"
                            fill="#fef8db"
                        />
                        <path d="M141.2,249.3c0,.1,0,.2-.1.3,0,0,0-.2.1-.3Z" />
                        <path d="M141.1,249.6c0,0,0,.1,0,.2,0,0,0-.1,0-.2Z" />
                        <path d="M134.6,243.4c-.1-.1-.2-.2-.3-.4.1.1.2.2.3.4Z" />
                        <path d="M134.3,243.1c-.1-.1-.2-.2-.3-.3.1,0,.2.2.3.3Z" />
                        <path d="M128.1,255.2c-.1.3-.2.6-.4.9h0c.1-.3.2-.6.4-.9Z" />
                        <path
                            d="M113.1,202.9c.1,4.1-3.8,8-8,8-4.1,0-7.9-3.7-7.9-7.9,0-4.4,3.8-8.3,8.1-7.9,3.8-.3,7.7,3.5,7.8,7.7Z"
                            fill="#fef8db"
                        />
                        <path
                            d="M113.1,355.3c0,4.2-4.1,8.2-8.1,7.9-4.5-.4-7.6-3.4-7.8-8-.1-4.2,3.7-7.9,8.1-7.9,4.4.1,7.7,3.7,7.8,7.9Z"
                            fill="#fef8db"
                        />
                        <path
                            d="M90,271.4c4.5.2,7.6,3.3,7.6,7.9,0,4.3-3.3,7.5-7.7,7.6-3.7,0-8.1-3.2-7.7-7.8-.5-3.8,3.5-7.9,7.8-7.7Z"
                            fill="#fef8db"
                        />
                    </g>
                </g>
                <g id="pique">
                    <path
                        d="M262.5,62.7h54.9c5.8,0,9.3,6.3,6.4,11.2l-27.4,46.1c-2.9,4.8-9.9,4.8-12.8,0l-27.4-46.1c-2.9-5,.6-11.2,6.4-11.2Z"
                        fill="#fde324"
                    />
                </g>
            </svg>
        </div>
    );
}

// eslint-disable-next-line @eslint-react/no-prop-types
GameWheel.propTypes = propTypes;

export default GameWheel;
