import { useIntersectionObserver } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../../styles/animations/jeu-aide-machine/playing-card.module.css';

import Clubs from '../../assets/img/animations/jeu-aide-machine/clubs.png';
import Diamonds from '../../assets/img/animations/jeu-aide-machine/diamonds.png';
import Hearts from '../../assets/img/animations/jeu-aide-machine/hearts.png';
import Spades from '../../assets/img/animations/jeu-aide-machine/spades.png';

const suits = {
    clubs: Clubs,
    diamonds: Diamonds,
    hearts: Hearts,
    spades: Spades,
};

const propTypes = {
    number: PropTypes.string,
    suit: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    cardClassName: PropTypes.string,
    children: PropTypes.node,
};

function PlayingCard({ number = null, suit = null, text = null, className }) {
    const color = suit === 'diamonds' || suit === 'hearts' ? 'red' : 'black';
    const image = suits[suit] || null;
    const {
        ref: containerRef,
        entry: { isIntersecting = false },
    } = useIntersectionObserver({ rootMargin: '-20%' });

    return (
        <div
            className={classNames([
                styles.container,
                className,
                { [styles.visible]: isIntersecting, [styles[color]]: color },
            ])}
            ref={containerRef}
        >
            <div className={styles.inner}>
                <div className={classNames([styles.corner, styles.top])}>
                    <div className={styles.number}>{number}</div>
                    {image !== null ? <img src={image} alt={suit} className={styles.suit} /> : null}
                </div>
                {text !== null ? <p className={styles.text}>{text}</p> : null}
                <div className={classNames([styles.corner, styles.bottom])}>
                    <div className={styles.number}>{number}</div>
                    {image !== null ? <img src={image} alt={suit} className={styles.suit} /> : null}
                </div>
            </div>
        </div>
    );
}

// eslint-disable-next-line @eslint-react/no-prop-types
PlayingCard.propTypes = propTypes;

export default PlayingCard;
